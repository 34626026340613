import React, {useEffect, useState} from "react"
import {connect} from "react-redux"
import "styles/pages/profile.sass"
import {httpClient} from "../../funcs";
import {openInNewTab} from "../../methods/openInNewTab";
import notifyUser, {notifyAboutError} from "../../methods/notifyUser";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ReactComponent as Delete} from "../Icons/size24/Delete.svg";
import {checkPushPermission, registerWebPush, unregisterWebPush, isPushAvailable, getCurrentPushSubscription} from "../../utils/webPushNotifications";

type Props = {
    $store: any,
    $commitToStore: Function
}

enum TGBotStatus {
    awaitingConfirmation = "AWAITING_CONFIRMATION",
    confirmed = "CONFIRMED"
}

let TGBotIntegrationView: any = function TGBotIntegrationView(props: Props) {
    const [pushEnabled, setPushEnabled] = useState(false);
    const [pushAvailable, setPushAvailable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        checkPushAvailability();
    }, []);

    async function checkPushAvailability() {
        // Проверяем техническую возможность
        const available = await isPushAvailable();
        setPushAvailable(available);
        
        if (!available) {
            setPushEnabled(false);
            return;
        }

        // Проверяем разрешения и наличие активной подписки
        const permission = await checkPushPermission();
        const hasActiveSubscription = await getCurrentPushSubscription();
        
        setPushEnabled(permission === 'granted' && hasActiveSubscription);
    }

    async function notifyAboutPush() {
        const available = await isPushAvailable();
        if (!available) {
            notifyUser(props, "Уведомления недоступны", "Ваш браузер не поддерживает веб-push уведомления");
            return;
        }

        const permission = await checkPushPermission();

        if (permission === 'denied') {
            notifyUser(props, "Уведомления заблокированы", 
                "Вы заблокировали уведомления для этого сайта. Чтобы включить их, измените настройки уведомлений в браузере");
        }
    }

    async function togglePushNotifications() {
        try {
            setIsLoading(true);
            if (!pushEnabled) {
                await registerWebPush();
                setPushEnabled(true);
            } else {
                await unregisterWebPush();
                setPushEnabled(false);
            }
        } catch (error) {
            notifyAboutError(props, error);
            // В случае ошибки перепроверяем состояние
            await checkPushAvailability();
        } finally {
            setIsLoading(false);
        }
    }

    function makeWebPushSection() {
        if (!pushAvailable) {
            return (
                <div>
                    <div className="commonSubtitle">
                        Уведомления в браузере недоступны в вашем браузере
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className={"titleContainer"}>
                    <div className="Subheader_17">
                        Уведомления в браузере
                    </div>
                    <label className={`switch ${isLoading ? 'disabled' : ''}`}>
                        <input
                            type="checkbox"
                            checked={pushEnabled}
                            onChange={togglePushNotifications}
                            disabled={isLoading}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
                <div className="Body_15" style={{marginTop: "10px"}}>
                    {isLoading 
                        ? "Подождите..." 
                        : (pushEnabled 
                            ? "Вы будете получать уведомления о новых подписанных договорах в браузере"
                            : "Включите, чтобы получать уведомления о новых подписанных договорах в браузере"
                        )
                    }
                </div>
            </div>
        );
    }

    async function requestCodeForTGBot() {
        httpClient.post('/integrate-tg-bot').then(({ data }) => {
            let tg_contacts = tgContacts()
            tg_contacts.push(
                {
                    status: "AWAITING_CONFIRMATION",
                    code: data.code
                }
            )

            props.$commitToStore({
                userSession: {
                    ...props.$store.userSession,
                    tg_contacts: tg_contacts
                },
            })
        }).catch((error) => {
            notifyAboutError(props, error)
        })
    }
    async function turnOffTGBot(contact: any) {
        httpClient.post(
            '/turn-off-tg-bot',
            {
                contact
            }
        ).then(({ data }) => {
            props.$commitToStore({
                userSession: {
                    ...props.$store.userSession,
                    tg_contacts: data
                },
            })
        }).catch((error) => {
            notifyAboutError(props, error)
        })
    }

    function tgContacts() {
        return props.$store.userSession?.tg_contacts ?? []
    }

    function makeContactView(contact: any) {
        if (!contact) {
            return
        }
        if (contact.status == TGBotStatus.awaitingConfirmation) {
            return <div>
                <div className="row-data">
                    <div>
                        <div>
                            <div className="commonSubtitle">
                                Отправьте боту <a href={`https://t.me/${process.env.REACT_APP_TG_ENTREPRENEUR_NOTIFICATIONS_BOT_NAME}`} target="_blank">@{process.env.REACT_APP_TG_ENTREPRENEUR_NOTIFICATIONS_BOT_NAME}</a> ключ:
                            </div>
                            <div className={"buttonsContainer"}>
                                <div className="commonLabel">
                                    {contact.code}
                                </div>
                                <button
                                    className="new smallIconed"
                                    onClick={() => {
                                        turnOffTGBot(contact)
                                    }}
                                >
                                    <Delete className="icon dark"/>
                                </button>
                            </div>

                        </div>
                        <div className="buttonsContainer" style={{marginTop: "10px"}}>
                            <CopyToClipboard
                                onCopy={() => {
                                    notifyUser(props, "Ключ скопирован, теперь отправьте его в бот. Телеграм откроется в течении 4 секунд");
                                    setTimeout(() => {
                                        openInNewTab(`https://t.me/${process.env.REACT_APP_TG_ENTREPRENEUR_NOTIFICATIONS_BOT_NAME}`)
                                    }, 4000)

                                }}
                                text={contact.code}
                            >
                                <button
                                    className="new withTitle secondary small"
                                >
                                    Скопировать ключ
                                </button>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
                <hr className="newHR"/>
            </div>
        }

        if (contact.status == TGBotStatus.confirmed) {
            return <div>
                <div className={"buttonsContainer"}>
                    {
                        contact.first_name &&
                        <div className={"commonSubtitle"}>
                            {contact.first_name}
                        </div>
                    }
                    {
                        contact.username &&
                        <a href={`https://t.me/${contact.username}`} target="_blank">@{contact.username}</a>
                    }
                    {
                        !contact.first_name && !contact.username &&
                        <div className={"commonSubtitle"}>
                            Телеграм бот подключен
                        </div>
                    }


                    <button
                        className="new smallIconed"
                        onClick={async () => {
                            await turnOffTGBot(contact)
                        }}
                    >
                        <Delete className="icon dark"/>
                    </button>
                </div>
            </div>
        }
    }

    return <div className={"templateViewContainer disabledHover"}>
        <div className="H3_21" style={{textAlign: "left", marginBottom: "10px"}}>
            Уведомления
        </div>

        {makeWebPushSection()}
        
        <hr className={"newHR"}/>

        <div className="Subheader_17" style={{marginBottom: "10px"}}>
            Уведомления в телеграм
        </div>

        <div className="Body_15" style={{textAlign: "left"}}>
            Настройте телеграм бота, чтобы получать подписанные договора прямо в мессенджере
        </div>

        {
            tgContacts().map((contact: any) => {
                return makeContactView(contact)
            })
        }

        <div className="row-data">
            <button
                className="new withTitle small"
                onClick={async () => {
                    await requestCodeForTGBot()
                }}
            >
                {
                    tgContacts().length > 0 ? "Подключить еще один аккаунт" : "Подключить телеграм бот"
                }
            </button>
        </div>
    </div>
}

TGBotIntegrationView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(TGBotIntegrationView)

export default TGBotIntegrationView