import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {connect} from "react-redux";
import {
    passportEntities,
    clientPassportNumber,
    getSystemEntityById,
    bulkUpdateSystemEntities,
    clientPassportType,
    PassportType,
    clientPassportRegistration,
    clientPassportPhoto,
    clientPassportSelfiePhoto,
    clientPassportIssuedBy,
    clientPassportIssuedByCode,
    clientPassportIssuedAt,
    clientPassportRegistrationPhoto,
    clientSignature,
} from "../../../methods/SystemEntity";
import ChooseWhoCanFillView from "./Views/ChooseWhoCanFillView";
import {contractSystemEntity, contractSystemEntityIsHidden} from "../../../methods/systemEntityValueByKeyword";
import EntityCopyButton from "./Views/EntityCopyButton";
import RequestedEntityView from "./Views/RequestedEntityView";
import ChooseTargetUserPassportTypeView from "./Views/ChooseTargetUserPassportTypeView";
import {Checkbox} from "@mui/material";
import {ReactComponent as CheckboxIcon} from "pages/Icons/size24/Checkbox.svg";
import {ReactComponent as CheckboxChecked} from "pages/Icons/size24/CheckboxChecked.svg";
import { on } from 'events';


let PassportSettingsView: any = function PassportSettings(props: any) {
    const {contract} = props

    const [onlyFromCamera, setOnlyFromCamera] = useState(
        contractSystemEntity(contract, clientPassportPhoto.id)?.onlyFromCamera == true 
        || contractSystemEntity(contract, clientPassportSelfiePhoto.id)?.onlyFromCamera == true 
        || contractSystemEntity(contract, clientPassportRegistrationPhoto.id)?.onlyFromCamera == true
    );

    function shouldShowRussianPassportFields(): boolean {
        let passportTypeEntity = contractSystemEntity(contract, clientPassportType.id) ?? clientPassportType;
        let selectedType = passportTypeEntity.selectedValues?.includes(PassportType.russianPassport);
        return selectedType === true;
    }

    function copyButton(entityId: string) {
        let entity = getSystemEntityById(entityId)
        if (contractSystemEntityIsHidden(contract, entityId) || !entity || entity.type == "Фото" || entity.type == "Подпись") {
            return
        }
        return <EntityCopyButton keyword={entity.keyword}/>
    }

    function passportEnabledEntityView() {
        return <RequestedEntityView
            title={"Паспорт обязателен для подписания документа?"}
            checked={!contractSystemEntityIsHidden(contract, clientPassportNumber.id)}
            updateChecked={(checked: boolean) => {
                let entitiesToUpdate = bulkUpdateSystemEntities(contract, passportEntities, "hidden", !checked)
                props.addEntities(true, entitiesToUpdate)
            }}
        />
    }

    function passportTypeEntityView() {
        return <ChooseTargetUserPassportTypeView
            entity={contractSystemEntity(contract, clientPassportType.id) ?? clientPassportType}
            addEntities={props.addEntities}
        />
    }

    function passportNumberEntityView() {
        return <RequestedEntityView
            title={"Серия и номер паспорта"}
            subtitle={"Обязательно для заполнения"}
            disabled={true}
            checked={true}
        />
    }

    function passportEntityView(entityId: string, title: string, subtitle: string | null = null) {
        return <RequestedEntityView
            entityId={entityId}
            title={title}
            subtitle={subtitle}
            disabled={false}
            checked={!contractSystemEntityIsHidden(contract, entityId)}
            updateChecked={(checked: boolean, entityId: string) => {
                let entity = getSystemEntityById(entityId)
                if (entity) {
                    if (checked) {
                        props.addEntities(true, [{...entity, hidden: false}])
                    } else {
                        props.deleteEntities(true, [entity.keyword])
                    }
                }
            }}
        />
    }

    function updateOnlyFromCamera(checked: boolean) {
        setOnlyFromCamera(checked);
        const passportPhotoEntities = [
            contractSystemEntity(contract, clientPassportPhoto.id) ?? clientPassportPhoto,
            contractSystemEntity(contract, clientPassportSelfiePhoto.id) ?? clientPassportSelfiePhoto,
            contractSystemEntity(contract, clientPassportRegistrationPhoto.id) ?? clientPassportRegistrationPhoto
        ];
        
        const entitiesToUpdate = passportPhotoEntities.map(entity => ({
            ...entity,
            onlyFromCamera: checked
        }));
        
        props.addEntities(true, entitiesToUpdate);
    }

    function passportPhotoRestrictionCheckbox() {
        const hasPassportPhoto = !contractSystemEntityIsHidden(contract, clientPassportPhoto.id) ||
            !contractSystemEntityIsHidden(contract, clientPassportSelfiePhoto.id) ||
            !contractSystemEntityIsHidden(contract, clientPassportRegistrationPhoto.id);
            
        if (!hasPassportPhoto) return null;
        
        return <>
            <hr className='newHR'/>

            <div className={"titleContainer"} style={{marginTop: "15px"}}>
                <div className="buttonsContainer">
                    <Checkbox
                        sx={{padding: "0px"}}
                        icon={<CheckboxIcon className="icon"/>}
                        checkedIcon={<CheckboxChecked className="icon"/>}
                        checked={onlyFromCamera}
                        onChange={({target: {checked}}) => {
                            updateOnlyFromCamera(checked);
                        }}
                    />
                    <div className="commonLabel">
                        Запретить загрузку фото из галереи (только с камеры)
                    </div>
                </div>
            </div>
            </>
    }

    return <div>
        {passportEnabledEntityView()}

        {
            !contractSystemEntityIsHidden(contract, clientPassportNumber.id) &&
            <>
                <div style={{marginTop: "20px"}}>
                    <ChooseWhoCanFillView
                        editable={(contractSystemEntity(contract,clientPassportNumber.id) ?? clientPassportNumber).editable}
                        updateEditable={(editable: boolean) => {
                            let entitiesToUpdate = bulkUpdateSystemEntities(contract, passportEntities, "editable", editable)
                            props.addEntities(true, entitiesToUpdate)
                        }}
                    />
                </div>

                <div style={{marginTop: "20px"}}>
                    {passportTypeEntityView()}
                </div>

                <div className="commonLabel" style={{marginTop: "20px"}}>
                    Какие данные паспорта необходимы?
                </div>

                <div className={"titleContainer"}>
                    {passportNumberEntityView()}
                    {copyButton(clientPassportNumber.id)}
                </div>
                <div className={"titleContainer"}>
                    {passportEntityView(clientPassportIssuedAt.id, "Дата выдачи")}
                    {copyButton(clientPassportIssuedAt.id)}
                </div>
                {
                    shouldShowRussianPassportFields() &&
                    <div className={"titleContainer"}>
                        {passportEntityView(clientPassportIssuedByCode.id, "Код подразделения", "Рекомендуем использовать это поле вместо \"Кем выдан паспорт\"")}
                        {copyButton(clientPassportIssuedByCode.id)}
                    </div>
                }
                {
                    shouldShowRussianPassportFields() &&
                    <div className={"titleContainer"}>
                        {passportEntityView(clientPassportIssuedBy.id, "Кем выдан паспорт", "Обычно, это очень длинная строка, и мы не рекомендуем запрашивать ее у ваших клиентов")}
                        {copyButton(clientPassportIssuedBy.id)}
                    </div>
                }
                {
                    shouldShowRussianPassportFields() &&
                    <div className={"titleContainer"}>
                        {passportEntityView(clientPassportRegistration.id, "Адрес прописки", )}
                        {copyButton(clientPassportRegistration.id)}
                    </div>
                }
                
                <div className={"titleContainer"}>
                    {passportEntityView(clientSignature.id, "Рукописная подпись клиента")}
                    {copyButton(clientSignature.id)}
                </div>

                <div className={"titleContainer"}>
                    {passportEntityView(clientPassportPhoto.id, "Фото паспорта")}
                    {copyButton(clientPassportPhoto.id)}
                </div>
                <div className={"titleContainer"}>
                    {passportEntityView(clientPassportSelfiePhoto.id, "Селфи с паспортом")}
                    {copyButton(clientPassportSelfiePhoto.id)}
                </div>
                {
                    shouldShowRussianPassportFields() &&
                    <div className={"titleContainer"}>
                        {passportEntityView(clientPassportRegistrationPhoto.id, "Фото прописки паспорта")}
                        {copyButton(clientPassportRegistrationPhoto.id)}
                    </div>
                }
                {passportPhotoRestrictionCheckbox()}
            </>
        }
    </div>
}

PassportSettingsView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(PassportSettingsView)

export default PassportSettingsView
