import React, {useEffect, useState} from "react"
import { connect } from "react-redux"
import "styles/pages/profile.sass"
import {httpClient} from "../../funcs";
import {openInNewTab} from "../../methods/openInNewTab";
import notifyUser, {notifyAboutError} from "../../methods/notifyUser";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Popup from "../../components/Popup";
import Checkbox from "@mui/material/Checkbox";
import {CircularProgress} from "@mui/material";
import EnterTextPopup from "../Common/EnterTextPopup";
import {TextareaStyled} from "../Styles/CommonCSSProperties";

type Props = {
    close: Function,
    onCollectBonus: Function,
    $store: any,
    $commitToStore: Function
}

let RequestPaymentLinkPopup: any = function RequestPaymentLinkPopup(props: Props) {

    const [tariff, setTariff] = useState("")
    const [sendTo, setSendTo] = useState("")
    const [enterDataPopup, setEnterDataPopup] = useState(false)

    async function requestBill() {
        try {
            await httpClient.post('/request-bill', {
                'tariff': tariff,
                'send_to': sendTo,
                'type': 'link'
            })
            setEnterDataPopup(false)
        } catch (error) {
            notifyAboutError(props, error)
        }
    }

    return <div className={"templateViewContainer disabledHover"}>
        <div className="commonTitle" style={{textAlign: "left"}}>
            Заказать ссылку на оплату в другой валюте
        </div>
        <div className="commonSubtitle" style={{textAlign: "left"}}>
            Вы можете оплатить тариф в любой валюте по специальной платежной ссылке
            <br/>
            Минимальная сумма оплаты - 7500 рублей
        </div>
        <hr className={"newHR"}/>
        <div className="row-data">
            <button
                className="new withTitle _wa"
                onClick={() => setEnterDataPopup(true)}
            >
                Запросить ссылку на оплату
            </button>
        </div>

        {
            enterDataPopup &&
            <Popup onClose={() => {setEnterDataPopup(false)}}>
                <div>
                    <div className="commonLabel" style={{marginTop: "25px", textAlign: "center"}}>
                        Заполните форму
                    </div>

                    <div className="form-field">
                        <TextareaStyled
                            value={tariff}
                            maxRows={1}
                            onKeyPress={(e: any) => {
                                if(e.key === 'Enter')
                                    e.preventDefault()
                            }}
                            placeholder={"Какой тариф вам нужен?"}
                            onChange={({target: {value}}: any) => {
                                setTariff(value)
                            }}
                        />
                    </div>

                    <div className="form-field">
                        <TextareaStyled
                            value={sendTo}
                            maxRows={1}
                            onKeyPress={(e: any) => {
                                if(e.key === 'Enter')
                                    e.preventDefault()
                            }}
                            placeholder={"Куда прислать ссылку?"}
                            onChange={({target: {value}}: any) => {
                                setSendTo(value)
                            }}
                        />
                    </div>

                    <hr className="newHR"/>

                    <button
                        className="new withTitle _wa"
                        disabled={tariff.length == 0 || sendTo.length == 0}
                        onClick={async () => {
                            await requestBill()
                        }}
                    >
                        Запросить ссылку на оплату
                    </button>
                </div>

            </Popup>
        }
    </div>
}

RequestPaymentLinkPopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(RequestPaymentLinkPopup)

export default RequestPaymentLinkPopup