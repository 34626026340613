import {useState} from "react";
import {connect} from "react-redux";
import {DocumentType} from "../../methods/DocumentType";
import notifyUser, {notifyAboutError} from "../../methods/notifyUser";
import {validate, validateMany} from "../../validate";
import "pages/Styles/EditContractCommon.sass"
import "pages/Styles/EditContractEntities.sass"
import {Entity, Flow } from "./Entity";
import {ReactComponent as AddEntity} from "../Icons/size24/Add.svg"
import EntityFormPopup from "./EntityFormPopup";
import {emptyEntityForm, EntityForm, makeEntityFormFromEntity} from "./EntityForm";
import {TemplateGuideView} from "../Templates/TemplatesHelpPopup";
import {trackGoal} from "../../methods/trackGoal";
import CreateContractView from "../CreateContract/CreateContractView";

type Props = {
    template: any,
    updateEntity: Function,
    addEntities: Function,
    deleteEntities: Function,
    renameEntity: Function,
    setOrderForEntity: Function,
    didTapScroll: Function,
    toggleGuideExpanded: Function,
    updateActualUserCardId: Function,
    updateContractSettings: Function,
    isTemplate: boolean,
    $store: any,
}

let Entities: any = function Entities(props: Props) {
    const {template, updateEntity, addEntities, deleteEntities, renameEntity, setOrderForEntity, didTapScroll} = props
    const [entityForm, setEntityForm] = useState<EntityForm | null>(null)
    const [systemEntitiesPopup, setSystemEntitiesPopup] = useState(false)
    const documentType = template.document_type

    function addOrUpdate(form: EntityForm, isDublicated: boolean = false) {
        let {isEdit, type, keyword, value, description, adminCanFill, clientCanFill, isRequired, columns, list, formula, needNumberToWords, currency, postfix, defaultValue, allowMultipleValues, onlyFromCamera} = form

        // Prepare data
        keyword = keyword.trim()
        description = description.trim()

        try {
            const valueForValidation = [
                {
                    rule: "contractDirectiveKeyword",
                    entity: keyword,
                    displayFieldText: "Поле",
                },
            ];

            // Validate input data
            let validationErrors = validateMany(valueForValidation);

            if (
                type === "Список" &&
                (list.length === 0 ||
                    list.filter((value: string) => (value ?? "").length === 0).length > 0)
            ) {
                validationErrors = [
                    ...validationErrors,
                    "Добавьте хотя бы одно значение в список",
                ];
            }

            if (validationErrors.length) throw new Error(validationErrors.join("\n"));

            // Check same directive
            if (!isEdit) {
                let validationError = validate(
                    "contractDirectivesList",
                    JSON.stringify(
                        template.entities.concat([
                            {
                                type,
                                keyword,
                                value,
                                description,
                                adminCanFill,
                                clientCanFill,
                                isRequired,
                                columns,
                                list,
                                formula,
                                needNumberToWords,
                                currency,
                                postfix,
                                defaultValue,
                                allowMultipleValues,
                                onlyFromCamera
                            },
                        ]),
                    ),
                )
                if (validationError.length) {
                    notifyUser(props, "Возникла ошибка", validationError)
                    return
                }
            }

            if (isEdit) {
                let entities = template.entities;
                const entityIndex = entities.findIndex(
                    (entity: any) => entity.keyword === keyword,
                );
                let entity = entities[entityIndex];

                if (entity.type == "Список" && !list.includes(entity.value)) {
                    entity.value = ""
                } else {
                    entity.value = value
                }

                entity.type = type;
                entity.keyword = keyword;
                entity.description = description;
                entity.adminCanFill = adminCanFill
                entity.clientCanFill = clientCanFill;
                entity.isRequired = isRequired;
                entity.columns = columns;
                entity.list = list;
                entity.formula = formula;
                entity.needNumberToWords = needNumberToWords;
                entity.currency = currency;
                entity.postfix = postfix;
                entity.defaultValue = defaultValue
                entity.allowMultipleValues = allowMultipleValues
                entity.onlyFromCamera = onlyFromCamera

                if (!value && defaultValue) {
                    entity.value = defaultValue
                }

                updateEntity(false, entity)
            } else {
                let entity = {
                    type,
                    keyword,
                    value: defaultValue ?? '',
                    description,
                    clientCanFill,
                    adminCanFill,
                    isRequired,
                    columns,
                    list,
                    formula,
                    needNumberToWords,
                    currency,
                    postfix,
                    defaultValue,
                    allowMultipleValues,
                    onlyFromCamera,
                }
                addEntities(false, [entity])
            }

            if (!isDublicated) {
                setEntityForm(null)
                notifyUser(props, isEdit ? "Поле обновлено" : "Поле создано")
            }
        } catch (error) {
            notifyAboutError(props, error)
        }
    }

    function myEntities() {
        if (documentType === DocumentType.PACKAGE) {
            return null
        }

        return <div className="editContractEntitiesContainer">
            <div className="titleContainer">
                <div className="editContractEntitiesTitle">
                    Мои Поля
                </div>
                {
                    (template.can_edit_template || template.can_edit_contract) && (
                        <button
                            className="new mediumIconed"
                            onClick={() => {
                                setEntityForm(emptyEntityForm())
                            }}
                        >
                            <AddEntity className="icon" style={{fill: "white"}}/>
                        </button>
                    )
                }
            </div>
            <div className="editContractEntitiesTip">
                <span>Поле - это аналог прочерка в бумажном договоре. Прежде чем выставить договор - заполните все поля помеченные </span>
                <span style={{color: 'red'}}>*</span>
            </div>
            {/*<div className="editContractEntitiesCloseTip">*/}
            {/*    Скрыть*/}
            {/*</div>*/}
            {template.entities.length > 0 &&
                <>
                    {
                        template.entities.map((entity: any, i: number) =>
                            <Entity
                                key={entity.keyword}
                                template={template}
                                entity={entity}
                                isSystem={false}
                                edit={() => {
                                    setEntityForm(makeEntityFormFromEntity(entity))
                                }}
                                update={updateEntity}
                                didTapScroll={didTapScroll}
                                flow={Flow.InputAndSetup}
                            />,
                        )
                    }
                </>
            }
        </div>
    }

    return <div className={"sectionsContainer"}>
        <TemplateGuideView
            template={template}
            toggleExpanded={props.toggleGuideExpanded}
        />

        <CreateContractView
            contract={template}
            updateActualUserCardId={props.updateActualUserCardId}
            updateContractSettings={props.updateContractSettings}
            addEntities={props.addEntities}
            deleteEntities={props.deleteEntities}
            isTemplate={props.isTemplate ?? true}
            $store={props.$store}
        />

        {
            template.body_type !== 'pdf' &&
            myEntities()
        }

        {
            entityForm &&
            <EntityFormPopup
                form={entityForm}
                close={() => {
                    setEntityForm(null)
                }}
                callback={(form: EntityForm) => {
                    addOrUpdate(form)
                    setEntityForm(null)
                }}
                deleteEntities={(keyword: string) => {
                    deleteEntities(false, [keyword])
                    notifyUser(props, "Поле удалено")
                    setEntityForm(null)
                }}
                renameEntity={renameEntity}
                setOrderForEntity={setOrderForEntity}
                dublicateEntity={() => {
                    let index = 1
                    let newKW = ""
                    while (true) {
                        newKW = `${entityForm?.keyword ?? ""} ${index}`.trim()
                        if (!template.entities.filter((entity: any) => entity.keyword == newKW)[0]) {
                            break
                        }
                        index += 1
                    }
                    let dublicatedForm = {
                        ...entityForm,
                        isEdit: false,
                        keyword: newKW
                    }
                    addOrUpdate(dublicatedForm, true)
                    setEntityForm(null)
                    setTimeout(() => {
                        setEntityForm({...dublicatedForm, isEdit: true})
                        notifyUser(props, "Поле дублировано")
                    }, 300)

                }}

                template={template}
                isTemplate={props.isTemplate}
            />
        }
    </div>
}

Entities = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(Entities)

export default Entities