import React from 'react';

type Props = {
    name: string;
    size?: number;
    isActive?: boolean;
    borderWidth?: number;
}

export const UserAvatar: React.FC<Props> = ({ name, size = 24, isActive = false, borderWidth = 2 }) => {
    // Получаем инициалы из имени
    const getInitials = (name: string) => {
        // Список организационно-правовых форм для фильтрации
        const legalForms = [
            'ИП',
            'Индивидуальный предприниматель',
            'ООО',
            'ОАО',
            'ЗАО',
            'АО'
        ];

        // Очищаем имя от организационно-правовых форм
        let cleanName = name;
        legalForms.forEach(form => {
            cleanName = cleanName
                .replace(new RegExp(`^${form}\\s+`, 'i'), '') // В начале строки
                .replace(new RegExp(`\\s+${form}\\s+`, 'i'), ' ') // В середине строки
                .replace(new RegExp(`\\s+${form}$`, 'i'), ''); // В конце строки
        });

        // Убираем лишние пробелы
        cleanName = cleanName.trim();

        const parts = cleanName.split(' ');
        if (parts.length >= 2) {
            return `${parts[0][0]}${parts[1][0]}`.toUpperCase();
        }
        return cleanName.substring(0, 2).toUpperCase();
    };

    // Генерируем цвет на основе инициалов
    const getColorFromInitials = (initials: string) => {
        const colors = [
            '#FF6B6B', // красный
            '#4ECDC4', // бирюзовый
            '#45B7D1', // голубой
            '#96CEB4', // мятный
            '#F7D794', // желтый
            '#D4A5A5', // розовый
            '#9B59B6', // фиолетовый
            '#3498DB', // синий
            '#1ABC9C', // изумрудный
            '#F1C40F'  // оранжевый
        ];
        
        let hash = 0;
        for (let i = 0; i < initials.length; i++) {
            hash = initials.charCodeAt(i) + ((hash << 5) - hash);
        }
        
        return colors[Math.abs(hash) % colors.length];
    };

    const initials = getInitials(name);
    const backgroundColor = getColorFromInitials(initials);

    return (
        <div
            style={{
                width: size,
                height: size,
                borderRadius: '50%',
                backgroundColor,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#FFFFFF',
                fontSize: size * 0.4,
                fontWeight: 500,
                userSelect: 'none',
                border: isActive ? `${borderWidth}px solid #33B5FF` : 'none',
                boxSizing: 'border-box'
            }}
        >
            <span style={{ marginTop: '2px' }}>
                {initials}
            </span>
        </div>
    );
}; 