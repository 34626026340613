import React from 'react';
import { Delete } from "@mui/icons-material";

interface DocumentCellProps {
    name: string;
    onDelete?: (index: number) => void;
    onClick?: () => void;
    index?: number;
    isLast?: boolean;
    contractId?: string;
}

const DocumentCell: React.FC<DocumentCellProps> = ({ 
    name, 
    onDelete, 
    onClick,
    index = 0, 
    isLast = false,
    contractId 
}) => {
    return (
        <div 
            className="templateViewContainer"
            style={{ 
                marginBottom: !isLast ? "15px" : "0",
                cursor: onClick ? 'pointer' : 'default'
            }}
            onClick={onClick}
        >
            <div className="templateTitleContainer" 
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <div style={{ overflow: 'hidden', flexGrow: 1, marginRight: '10px' }}>
                    <div className="title" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {name}
                    </div>
                </div>
                {onDelete && (
                    <div style={{ flexShrink: 0 }}>
                        <button 
                            className="new smallIconed"
                            onClick={() => onDelete(index)}
                        >
                            <Delete className="icon dark"/>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DocumentCell; 