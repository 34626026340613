import {BaseLink, useRoute} from 'react-router5'
import React from 'react'
import classNames from 'classnames';
import {ReactComponent as Contract} from "pages/Icons/size24/Contract.svg";
import {ReactComponent as ContractsSigned} from "pages/Icons/size24/ContractSigned.svg";
import {UserAvatar} from './UserAvatar';
import {connect} from 'react-redux';
import {userFullname} from "../../methods/utils";

function MobileFooter(props: any) {
    let $router = useRoute();
    const [active, setActive] = React.useState<string>($router.route.name)
    const userSession = props.$store.userSession;
    const userName = userFullname(userSession);

    React.useEffect(() => {
        setActive($router.route.name)
    },[$router])

    return (
        <footer className="footer">
            <div>
                <BaseLink className={classNames({active: active === 'new-contract'})} router={$router.router} routeName="new-contract" routeParams={{act: 'list'}}>
                    <Contract/>
                    <p>Шаблоны</p>
                </BaseLink >
            </div>
            <div>
                <BaseLink router={$router.router} className={classNames({active: active === 'signed-contracts' || active === 'contract'})} routeName="signed-contracts">
                    <ContractsSigned/>
                    <p>Выставленные</p>
                </BaseLink>
            </div>
            <div>
                <BaseLink router={$router.router} routeName="profile" className={classNames({active: active === 'profile'})} >
                    <UserAvatar name={userName} isActive={active === 'profile'} borderWidth={1} />
                    <p>Профиль</p>
                </BaseLink>
            </div>
        </footer>
    )
}

export default connect(
    (store) => ({ $store: store })
)(MobileFooter);