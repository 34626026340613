import {connect} from "react-redux";
import React, {useState} from "react";
import {useRoute} from "react-router5";
import {Tooltip, useMediaQuery} from "@mui/material";

import {ReactComponent as Back} from "pages/Icons/size24/Back.svg"
import {ReactComponent as Edit} from "pages/Icons/size24/Edit.svg"
import {ReactComponent as Actions} from "pages/Icons/size24/ActionsHorizontal.svg"
import EnterTextPopup from "../../Common/EnterTextPopup";
import {CleaningServices, Delete, Queue, Share} from "@mui/icons-material";
import MenuButton from "../../../methods/MenuButton";
import {mobileMaxWidthMediaQuery} from "../../../methods/utils";

"../Icons/size24/ActionsHorizontal.svg"
type Props = {
    template: any,
    hasFile: boolean,
    hasMultipleFiles: boolean,
    isChanging: string
    saveContract: Function,
    saveNewName: Function,
    deleteFile: Function
    $store: any
}

let CreatePDFHeader: any = function CreatePDFHeader(props: Props) {
    const $router = useRoute();
    const {template, saveContract, isChanging, saveNewName} = props
    const [showEditName, setShowEditName] = useState(false)

    const mobile = useMediaQuery(mobileMaxWidthMediaQuery)

    function back() {
        $router.router.navigate('new-contract', {
            act: 'list'
        }, { reload: false })
    }

    function makeBackButton() {
        return <button
            className="new bigIconed"
            onClick={() => {back()}}
        >
            <Back className="icon dark"/>
        </button>
    }

    function mobileButtonsContainer(template: any) {
        return <div className="navbar">
            <div style={{padding: "20px 15px 15px 15px"}}>
                <div className="titleContainer">
                    <div className="buttonsContainer">
                        <button
                            className="new"
                            style={{width: "16px", height: "16px"}}
                            onClick={() => {back()}}
                        >
                            <Back className="smallIcon" style={{fill: "#75809E"}}/>
                        </button>
                        <div className="navbarHeader">
                            К списку шаблонов
                        </div>
                    </div>

                    <div className="serviceMessage">
                        {isChanging === "saving" ? "cохраняем..." : ""}
                        {isChanging === "saved" ? "cохранено" : ""}
                    </div>
                </div>
                <hr className="newHR"/>
                <div
                    className="titleContainer _wa"
                >
                    {
                        makeActionsButton()
                    }
                    {
                        saveButton()
                    }
                </div>
            </div>
        </div>
    }

    function makeActionsButton() {
        return <div/>
    }

    function saveButton() {
        return <div className="sign">
            <div className="buttonsContainer" style={{gap: "0px"}}>
                <button 
                    className="new withTitle" 
                    onClick={() => {saveContract(true)}}
                    disabled={!props.hasFile}
                >
                    {props.hasMultipleFiles ? "Создать пакет документов" : "Подписать и отправить"}
                </button>
            </div>
        </div>
    }
    
    function desktopButtonsContainer(template: any) {
        return <div className="titleContainer">
            <div className="buttonsContainer">
                {makeBackButton()}
            </div>
            <div className="buttonsContainer">
                <div className="serviceMessage">
                    {isChanging === "saving" ? "cохраняем..." : ""}
                    {isChanging === "saved" ? "cохранено" : ""}
                </div>

                {
                    saveButton()
                }
            </div>
        </div>
    }


    return <div>
        {
            mobile ? mobileButtonsContainer(template) : desktopButtonsContainer(template)
        }
        {
            props.hasFile &&
            <div className="buttonsContainer" style={{marginTop: mobile ? "148px" : "25px"}}>
                <div className="contractTitle" style={{
                    maxWidth: "calc(100% - 40px)",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                }}>
                    {template.name}
                </div>
                <button
                    className="new smallIconed"
                    onClick={() => {
                        setShowEditName(true)
                    }}
                >
                    <Edit className="icon dark"/>
                </button>
            </div>
        }

        {
            showEditName &&
            <EnterTextPopup
                title={"Введите новое название для шаблона"}
                placeholder={"Название шаблона"}
                oldText={template.name}
                onClose={() => {
                    setShowEditName(false)
                }}
                canSaveEmpty={false}
                saveNewName={(newName: string) => {
                    setShowEditName(false)
                    saveNewName(newName)
                }}
            />
        }
    </div>
}

CreatePDFHeader = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(CreatePDFHeader)

export default CreatePDFHeader
