import React, {useEffect, useRef, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {Autocomplete, useMediaQuery} from "@mui/material";
import {useRoute} from "react-router5";
import {connect} from "react-redux";
import {httpClient} from "../../funcs";
import {commonSelect, TextFieldStyled} from "../Styles/CommonCSSProperties";

let SelectTemplateView: any = function SelectTemplateView(props: any) {
    const [templates, setTemplates] = useState<any[]>([])
    const [isEditing, setIsEditing] = useState(false)
    const input = useRef<HTMLInputElement>(null);

    useEffect(
        () => {
            loadTemplate()
        },
        []
    )

    async function loadTemplate() {
        const {data} = await httpClient.get(`/templates`)
        setTemplates(data)
    }

    return <>
        <Autocomplete
            freeSolo
            handleHomeEndKeys={true}
            defaultValue={props.selectedTemplate?.title ?? ""}
            inputValue={props.selectedTemplate?.title ?? ""}
            className="classicStyleMuiSelect"
            options={
                templates.map((template) => {
                    return {id: template._id, title: template.name + '. ' + (template.comment ?? '')}
                })
            }
            getOptionLabel={(option) => { // @ts-ignore
                return option?.title ?? ""
            }}
            sx={commonSelect()}
            onFocus={({target}) => {
                setIsEditing(true)
            }}
            onBlur={({target}) => {
                setIsEditing(false)
            }}
            onChange={(e, value) => {
                // @ts-ignore
                console.log("didSelectTemplate", e, value)
                props.didSelectTemplate(value)
            }}
            renderInput={(params) => (
                <TextFieldStyled
                    inputRef={input}
                    {...params}
                    sx={{
                        border: isEditing ? "1px solid #75809E !important" : "none",
                        background: isEditing ? "white !important" : "#EAECF3",
                        padding: isEditing ? "1.5px !important" : "2.5px",
                    }}
                    variant="outlined"
                    placeholder={"Выберите шаблон"}
                />
            )}
        />
    </>
}

SelectTemplateView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(SelectTemplateView)

export default SelectTemplateView
