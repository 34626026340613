import { useEffect } from 'react';

// Глобальный стек для хранения истории навигации
const navigationStack: string[] = [];

export const useNavigationStack = () => {
    useEffect(() => {
        // При монтировании компонента добавляем текущий путь в стек
        const currentPath = window.location.pathname;
        navigationStack.push(currentPath);

        return () => {
            // При размонтировании удаляем текущий путь из стека
            const index = navigationStack.indexOf(currentPath);
            if (index > -1) {
                navigationStack.splice(index, 1);
            }
        };
    }, []);

    const goBack = () => {
        // Получаем предыдущий путь из стека
        const currentPath = window.location.pathname;
        const currentIndex = navigationStack.indexOf(currentPath);
        
        if (currentIndex > 0) {
            // Если есть предыдущий путь в стеке, переходим на него
            const previousPath = navigationStack[currentIndex - 1];
            window.history.pushState(null, '', previousPath);
        } else {
            // Если нет предыдущего пути, просто идем назад
            window.history.back();
        }
    };

    return { goBack };
}; 