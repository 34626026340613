import {httpClient, prepareSeveralPhoneNumbers} from "../funcs";
import {notifyAboutError} from "./notifyUser";
import {loadToken, removeToken, saveToken} from "./saveToken";
import {useRoute} from "react-router5";

export async function loadSelf(props: any) {
    loadToken()
    try {
        const { data: user } = await httpClient.get('/user/self')

        saveToken(user.access_token)

        const { data: balance } = await httpClient.get(`/get-balance`)

        props?.$commitToStore({
            userSession: user,
            balance: balance.balance
        })
    }
    catch (error) {
        notifyAboutError(props, error)
    }
}

export function signOut(props: any) {
    props.$commitToStore({
        userSession: null,
        balance: null,
        signedOut: true
    })
    localStorage.removeItem("registerForm")
    removeToken()
}