export function addWatermark(base64Image: string): Promise<string> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64Image;
        
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (!ctx) {
                reject('Canvas context not available');
                return;
            }

            // Установка размеров canvas равными размерам изображения
            canvas.width = img.width;
            canvas.height = img.height;

            // Отрисовка изображения
            ctx.drawImage(img, 0, 0);

            // Настройка водяного знака
            ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
            ctx.font = `${Math.max(20, img.width * 0.03)}px Arial`;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            // Поворот текста
            ctx.save();
            ctx.translate(canvas.width/2, canvas.height/2);
            ctx.rotate(-Math.PI/4);
            
            // Добавление текста несколько раз
            const text = 'ЗАПРЕЩЕНО КОПИРОВАТЬ И ИСПОЛЬЗОВАТЬ ОТДЕЛЬНО ОТ ДАННОГО ДОКУМЕНТА';
            const repeats = Math.ceil(Math.max(canvas.width, canvas.height) / 300);
            
            for(let i = -repeats; i <= repeats; i++) {
                ctx.fillText(text, 0, i * 100);
            }

            ctx.restore();

            resolve(canvas.toDataURL('image/jpeg', 0.9));
        };

        img.onerror = () => {
            reject('Failed to load image');
        };
    });
} 