import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {CircularProgress, useMediaQuery} from "@mui/material";
import {useRoute} from "react-router5";
import {connect} from "react-redux";
import Popup from "../../components/Popup";
import SelectTemplateView from "./SelectTemplateView";
import {httpClient} from "../../funcs";
import {notifyAboutError} from "../../methods/notifyUser";
import {sortArrayByOrder} from "../../methods/workWithEntities";

type Props = {
    contractId: string,
    close: Function,
    $commitToStore: Function
}

let AppendixPopup: any = function EmptyView(props: Props) {
    const [template, setTemplate] = useState<any>(null)
    const $router = useRoute()
    const [readyTemplates, setReadyTemplates] = useState<any[]>([])

    useEffect(() => {
        loadReadyTemplates()
    }, [])

    async function loadReadyTemplates() {
        try {
            const {data: templatesSection} = await httpClient.post("/ready-templates", { type: 'appendix'})
            setReadyTemplates(sortArrayByOrder(templatesSection[0]?.templates ?? []))
        } catch(error) {
            notifyAboutError(props, error)
        }
    }

    async function createAppendix(readyTemplate: boolean, templateId: string) {
        console.log("template_id", templateId)
        try {
            let result: any = await httpClient.post(
                `/appendix`,
                {
                    contract_id: props.contractId,
                    template_id: templateId,
                    ready_template: readyTemplate
                })

            $router.router.navigate(
                'contract',
                {
                    contractId: result.data._id
                }
            )

            location.reload()

        } catch (error) {
            notifyAboutError(props, error)
        }
    }

    function readyTemplateButtons() {
        return <div>
            {
                readyTemplates.map((readyTemplate: any, index) => {
                    console.log("readyTemplate", readyTemplates)
                    return <div
                        style={{marginTop: index == 0 ? "0px" : "15px" }}
                        className={"templateViewContainer disabledHover titleContainer"}
                    >
                        <div>
                            <div className={"commonLabel"}>
                                {readyTemplate.name}
                            </div>
                            {
                                readyTemplate.comment &&
                                <div className={"commonSubtitle"}>
                                    {readyTemplate.comment}
                                </div>
                            }
                        </div>

                        <button
                            className="new withTitle small"
                            onClick={async () => {
                                await createAppendix(true, readyTemplate._id)
                            }}
                        >
                            Выставить
                        </button>
                    </div>
                })
            }
        </div>
    }

    return <Popup
        onClose={props.close}
    >
        <>
            <div className="commonLabel">
                Выберите шаблон документа, который вы хотите создать
            </div>

            <div className="commonSubtitle" style={{marginTop: "15px"}}>
                 Значения полей из текущего документа будут перенесены в новый документ. "Номер договора" и "Текущая дата" основного договора будут записаны в приложение в новые поля "Номер оригинального документа" и "Дата оригинального документа" соответственно
            </div>

            <br/>

            {
                readyTemplates.length > 0 &&
                <>
                    <div className="commonSubtitle" style={{marginTop: "15px", marginBottom: "15px"}}>
                        Вы можете воспользоваться нашими готовыми шаблонами:
                    </div>

                    {
                        readyTemplateButtons()
                    }
                </>
            }

            <hr className={"newHR"}/>

            <div className="commonSubtitle" style={{marginTop: "15px"}}>
                Или можете выбрать любой свой шаблон:
            </div>

            <div style={{marginTop: "15px"}}>
                <SelectTemplateView
                    selectedTemplate={template}
                    didSelectTemplate={(value: any) => {
                        setTemplate(value)
                    }}
                />
            </div>

            <button
                className="new withTitle _wa"
                disabled={!template}
                style={{marginTop: "15px"}}
                onClick={async () => {
                    await createAppendix(false, template.id)
                }}
            >
                Создать приложение к этому документу
            </button>
        </>
    </Popup>
}

AppendixPopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(AppendixPopup)

export default AppendixPopup
