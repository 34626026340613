import { connect } from "react-redux"
import {BaseLink, useRoute} from "react-router5"
import BalanceHolder from "./nav/BalanceHolder";
import isAuthorized from "utils/funcs/isAuthorized";
import React from "react";
import { UserAvatar } from "./UserAvatar";
import { userFullname } from "../../methods/utils";
import NavBalance from './nav/NavBalance';

let Nav: any = function Nav(props: any) {
    const $router = useRoute()
    const userSession = props.$store.userSession;
    const userName = userFullname(userSession);

    const style = {
        textDecoration: "underline",
        textUnderlineOffset: "3px",
        textDecorationColor: "#33B5FF",
        textDecorationThickness: "2px"
    }

    return (
        isAuthorized() ?
            <nav className="profile-navigation">
                <BaseLink router={$router.router} routeName="new-contract" routeParams={{act: 'list'}}>
                    <button
                        className="_wa _transparent"
                        style={$router.route.name === 'new-contract' ? style : {}}
                    >
                        Шаблоны
                    </button>
                </BaseLink>
                <BaseLink router={$router.router} routeName="signed-contracts">
                    <button
                        className="_wa _transparent"
                        style={$router.route.name === 'signed-contracts' || $router.route.name === 'contract' ? style : {}}
                    >
                        Выставленные
                    </button>
                </BaseLink>
                <BaseLink router={$router.router} routeName="profile">
                    <button className="_wa _transparent" style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        padding: '8px 12px',
                        borderRadius: '8px',
                        transition: 'background-color 0.2s',
                    }}>
                        <UserAvatar 
                            name={userName} 
                            size={40} 
                            isActive={$router.route.name === 'profile'}
                        />
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: '0px',
                            marginTop: '0px'
                        }}>
                            {/* <span style={{
                                color: '#0E1F4D',
                                fontSize: '14px',
                                fontWeight: 600,
                                lineHeight: '18px',
                                marginBottom: '4px',
                                ...$router.route.name === 'profile' ? style : {}
                            }}>
                                Профиль
                            </span> */}
                            {userName && (
                                <>
                                    <span style={{
                                        color: '#0E1F4D',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        lineHeight: '18px',
                                        maxWidth: '300px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {userName}
                                    </span>
                                    <NavBalance />
                                </>
                            )}
                        </div>
                    </button>
                </BaseLink>
            </nav>
            : null
    )
}

Nav = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) })
)(Nav)

export default Nav
