import React from 'react'
import ReactDOM from 'react-dom'
import App from 'App'

import createRouter from 'router5'
import browserPlugin from 'router5-plugin-browser'
import { RouterProvider } from 'react-router5'

import {Provider} from "react-redux"
import store from "store"

// Create router
const router = createRouter([
  { name: 'main', path: '/main?signout&auth&register' },
  { name: 'register', path: '/register?firstName&middleName&lastName&email&phoneNumber&promoCode' },
  { name: 'another', path: '/another?signout&auth&register' },
  { name: 'new-contract', path: '/new-contract?act&templateId' },
  { name: 'signed-contracts', path: '/signed-contracts' },
  { name: 'contract-templates', path: '/contract-templates?act' },
  { name: 'template', path: '/template/:templateId' },
  { name: 'contract', path: '/contract/:contractId?is_first_signer' },
  { name: 'package', path: '/package/:packageId'},
  { name: 'profile', path: '/profile?signout&loaded&success&transactionId'},
  { name: 'invite', path: '/invite/:roleId'},
  { name: 'shared-template', path: '/shared-template/:shareLinkId'},
  { name: 'new-pdf-contract', path: '/new-pdf-contract'},
  { name: 'contract-statistics', path: '/contract-statistics?from&to'},
], {
  defaultRoute: 'main'
})
router.usePlugin(browserPlugin())
router.useMiddleware((router) => (fromState, toState, done) => {

  if (!fromState || !toState) return done()

  if (fromState.name !== toState.name)
    window.scrollTo(0, 0)

  done()
})

// Render
router.start(() => {
  ReactDOM.render(
      <React.StrictMode>
        <RouterProvider router={router}>
          <Provider store={store()}>
            <App />
          </Provider>
        </RouterProvider>
      </React.StrictMode>,
      document.getElementById('root')
  )
})
