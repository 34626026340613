import {Tooltip, useMediaQuery} from "@mui/material";
import React, {useRef} from "react";
import {connect} from "react-redux";
import "pages/Styles/EditContractCommon.sass"
import "pages/Styles/EditContractEntities.sass"
import notifyUser from "../../methods/notifyUser";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {TextareaStyled} from "../Styles/CommonCSSProperties";
import {ReactComponent as Warning} from "pages/Icons/size24/Warning.svg"
import {ReactComponent as Scroll} from "pages/Icons/size24/ArrowDown.svg"
import {ReactComponent as Edit} from "pages/Icons/size24/Edit.svg"
import {ReactComponent as Copy} from "../Icons/size24/Copy.svg";
import {PushPin, Settings, VisibilityOff} from "@mui/icons-material";
import {EntityInput} from "./EntityInput";
import {formatBytes} from "../../methods/formatBytes";
import {getPostfix, getPostfixForEntity} from "../../methods/utils";
import {trackGoal} from "../../methods/trackGoal";
import {cleanKeyword, fullEntityValue} from "../../methods/workWithEntities";
import {EntityAdditionalValuesView} from "./EntityAdditionalValuesView";

type Props = {
    template: any,
    entity: any,
    isSystem: boolean,
    canPin: boolean
    edit: Function,
    update: Function
    didTapScroll: Function,
    copied?: Function,
    flow: Flow
}

export enum Flow {
    InputAndSetup = 1,
    InputSystem,
    ReadOnly,
}

export let Entity: any = function Entity(props: Props) {
    const {template, entity, isSystem, canPin, edit, update, didTapScroll, copied, flow} = props

    const ref = useRef<typeof EntityInput>(null)

    function updateEntity(value: string, additionalValues: string[]) {
        if (isSystem) {
            updateSystemEntity(value)
            return
        }

        entity.value = value
        entity.postfix = getPostfixForEntity(entity)
        entity.additionalValues = additionalValues
        update(isSystem, entity)
    }

    function updateSystemEntity(value: string) {
        entity.value = value
        update(isSystem, entity)

        setTimeout(
            () => {
                let kw = `<<${entity.keyword}>>`;
                document.getElementsByName(kw).forEach((element: HTMLElement) => {
                    element.innerText = value ?? ""
                })
            },
            100
        )
    }

    function makeEntityTitle() {
        const cleanedKeyword = cleanKeyword(entity.keyword);
        if (!cleanedKeyword?.trim()) {
            return '';
        }

        let desc = `${cleanedKeyword}`;

        if (("isRequired" in entity && entity["isRequired"] === false) || 
            (entity.clientCanFill && ["Текст", "Фото", "Дата", "Список"].includes(entity.type)) || 
            (isSystem && entity.isRequired != true)) {
            return desc;
        } else {
            return <div>
                <span>{desc}</span>
                {
                    (flow == Flow.InputSystem || flow == Flow.InputAndSetup) &&
                    <span style={{color: 'red'}}>*</span>
                }
            </div>
        }
    }

    function makeInput() {
        if (flow == Flow.ReadOnly) {
            switch (entity.type) {
                case "Фото":
                    const size = (entity.value?.toString() ?? "").length > 100 ? formatBytes((entity.value?.toString() ?? "").length ?? 0, 0) : entity.value
                    return <div className="commonSubtitle">
                        {`Фото: ${size}`}
                    </div>
                default:
                    return <div
                        className="buttonsContainer editContractKeywordContainer"
                        style={{width: "100%"}}
                    >
                        {
                            entity.value &&
                            <CopyToClipboard
                                onCopy={onCopy}
                                text={entity.value}
                            >
                                <Tooltip arrow={true} title={"Скопировать значение поля"}>
                                    <button className="new smallIconed">
                                        <Copy className="icon" fill="#D4D8E1"/>
                                    </button>
                                </Tooltip>
                            </CopyToClipboard>
                        }
                        <div
                            className="commonSubtitle"
                            style={{whiteSpace: "pre-wrap"}}
                        >
                            {fullEntityValue(entity)}
                        </div>
                    </div>
            }
        }

        if (!(entity.adminCanFill ?? true)) {
            return <TextareaStyled
                disabled={true}
                value={""}
                placeholder={"Поле заполнит ваш клиент"}
            />
        }

        return <EntityInput
            myRef={ref}
            entity={entity}
            updateEntity={(value: string) => {
                updateEntity(value, entity.additionalValues)
            }}
        />
    }

    // Oncopy function
    function onCopy() {
        if (!entity.keyword?.trim()) {
            notifyUser(props, 'Невозможно скопировать пустую метку')
            return;
        }

        switch (flow) {
            case Flow.ReadOnly:
                notifyUser(props, 'Значение поля скопировано')
                break
            default:
                notifyUser(props, 'Метка поля скопирована', 'Теперь вставьте метку в текст договора')
        }

        if (copied) {
            copied()
        }
    }

    function scroll() {
        if (didTapScroll) {
            didTapScroll(entity.keyword)
        }

        let kw = `<<${entity.keyword}>>`;
        let element = document.getElementsByName(kw)[0]
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }
    }

    function makeScrollView() {
        if (mobile && template.body?.includes(`<<${entity.keyword}>>`)) {
            return <button
                className="new"
                style={{
                    marginTop: "10px",
                    marginBottom: "-5px"
                }}
                onClick={() => {
                    scroll()
                }}
            >
                <div className="buttonsContainer">
                    <div className="editContractEntitiesCloseTip">
                        Смотреть Поле в договоре
                    </div>
                    <Scroll className="smallIcon dark"/>
                </div>
            </button>
        }

        if (!isSystem && kwIsNotInBody() && flow != Flow.ReadOnly) {
            return noKWinTextErrorView()
        } else {
            return null
        }
    }


    function kwIsNotInBody(): boolean {
        if (!entity.keyword?.trim()) {
            return false;
        }
        return !template.body?.includes(`<<${entity.keyword}>>`) &&
            !template.body?.includes(`{{${entity.keyword}}}`)
    }

    function noKWinTextErrorView() {
        const entityRequired = !("isRequired" in entity) || entity["isRequired"] === true
        const color = entityRequired ? "#E22631" : "#E69138"
 
        const text = entityRequired
            ? <div>
                {`Метки поля нет в тексте договора. Скопируйте метку и вставьте в текст договора. Подробности можете посмотреть в `}
                <a style={{color: color, textDecorationLine: "underline", fontWeight: "bold"}} href={"https://rutube.ru/video/d379fca0fd22239f97424d8af7fc9000/?r=wd&t=602"} target="_blank">нашем видео</a>
              </div>

            : <div>{`Поле необязательно, но вы можете добавить метку в текст договора`}</div>

        return <div
            className="buttonsContainer"
            style={{
                marginTop: "10px",
                marginBottom: "-5px"
            }}
        >
            <div
                style={{width: "25px !important"}}
            >
                <Warning className="icon" style={{fill: color}}/>
            </div>
            <div
                className="editContractEntitiesCloseTip"
                style={{
                    color: color
                }}
            >
                {text}
            </div>
        </div>
    }

    const mobile = useMediaQuery("(max-width: 760px)");

    return <div className="editContractEntityContainer" id={`entityView.${entity.keyword}`}>
        <div className="titleContainer">
            <div className="editContractTitle">
                {makeEntityTitle()}
            </div>

            {
                (flow == Flow.InputSystem || flow == Flow.InputAndSetup) &&
                <div className="buttonsContainer">
                    {
                        isSystem && canPin && (entity.editable ?? true) &&
                        <button
                            className="new smallIconed"
                            onClick={() => {
                                trackGoal('pin_entity')
                                entity.pinned = !(entity.pinned ?? false)
                                update(isSystem, entity)
                            }}
                        >
                            {
                                (entity.pinned ?? false)
                                    ? <VisibilityOff className="icon dark"/>
                                    : <PushPin className="icon dark"/>
                            }
                        </button>
                    }
                    {
                        (entity.editable ?? true) && (entity.adminCanFill ?? true) && (flow == Flow.InputSystem || flow == Flow.InputAndSetup) &&
                        <button
                            className="new smallIconed"
                            onClick={() => {
                                ref?.current()
                            }}
                        >
                            <Edit className="icon dark"/>
                        </button>
                    }
                    {!isSystem && (flow == Flow.InputAndSetup) && (template.can_edit_template || template.can_edit_contract) &&
                        <button
                            className="new smallIconed"
                            onClick={() => {
                                edit()
                            }}
                        >
                            <Settings sx={{fill: "#75809E"}}/>
                        </button>
                    }
                </div>
            }
        </div>
        {
            (flow == Flow.InputSystem || flow == Flow.InputAndSetup) && <div className="buttonsContainer editContractKeywordContainer" id={`copyView.${entity.keyword}`}>
                <CopyToClipboard
                    onCopy={onCopy}
                    text={`{{${entity.keyword}}}`}
                >
                    <button className="new withTitle small">
                        Скопировать метку поля
                    </button>
                </CopyToClipboard>
            </div>
        }

        {
            flow != Flow.ReadOnly && entity.description &&
            <div className="editContractDescriptionLabel">
                {entity.description}
            </div>
        }

        {
            makeScrollView()
        }

        {
            ((entity.editable ?? true) || flow == Flow.ReadOnly) &&
            <>
                <hr className="newHR" />

                <div className="editContractInputContainer" id={`input.${entity.keyword}`}>
                    {makeInput()}
                </div>
            </>
        }
        {
            (entity.editable ?? true) && (entity.allowMultipleValues ?? false) && flow == Flow.InputAndSetup && entity.adminCanFill &&
            <EntityAdditionalValuesView
                myRef={ref}
                entity={entity}
                updateEntity={updateEntity}
            />
        }
    </div>
}

Entity = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(Entity)
