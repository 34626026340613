import React from 'react'
import Popup from 'components/Popup'
import { httpClient, nError } from 'funcs'
import { useRoute } from "react-router5"

import {Box, Button, Grid} from '@mui/material'
import ConfirmationPopup from "../Common/ConfirmationPopup";
import notifyUser, { notifyAboutError } from "../../methods/notifyUser";
import {DocumentType, getDocumentTypeText} from "../../methods/DocumentType";

type Props = {
    closePopup: Function,
    contractId: string,
    documentType: DocumentType,
    reload: Function,
    [type: string]: any,
    $commitToStore: Function
}

function DeleteContractPopup(props: Props) {
    const {closePopup, contractId, documentType, reload, loadContract} = props
    const $router = useRoute();
    async function hideContract() {
        try {
            switch (documentType) {
                case DocumentType.CONTRACT:
                    await httpClient.put(`/contracts/${contractId}/remove`)
                    break;
                case DocumentType.PACKAGE:
                    await httpClient.delete(`/contract-packages/${contractId}`)
                    break;
            }

            notifyUser(
                props,
                'Успех',
                `${getDocumentTypeText(documentType)} успешно удалён`
            )

            $router.router.navigate('signed-contracts')

            closePopup()
            reload()
        } catch (error) {
            notifyAboutError(props, error)
        }
    }
    return <ConfirmationPopup
        title={`Вы уверены, что хотите удалить ${getDocumentTypeText(documentType)}?`}
        onClose={closePopup}
        onConfirm={hideContract}
    />
}


export default DeleteContractPopup