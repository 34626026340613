import { httpClient } from 'funcs';

export async function registerWebPush() {
    try {
        if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
            console.log('Push notifications not supported');
            return;
        }

        // Регистрируем service worker
        const registration = await navigator.serviceWorker.register('/service-worker.js');
        console.log('Service Worker registered');

        // Запрашиваем разрешение на отправку уведомлений
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
            console.log('Notification permission denied');
            return;
        }

        // Получаем существующую подписку или создаем новую
        let subscription = await registration.pushManager.getSubscription();
        if (!subscription) {
            console.log('No existing subscription, creating new one', process.env.REACT_APP_VAPID_PUBLIC_KEY !== undefined);
            subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY ?? '')
            });
        }

        console.log('Subscription:', subscription);

        // Отправляем подписку на сервер
        await sendSubscriptionToServer(subscription);

    } catch (error) {
        console.error('Error registering push notification:', error);
    }
}

export async function unregisterWebPush() {
    try {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.getSubscription();
        
        if (subscription) {
            await httpClient.delete('/push-subscription', {
                data: subscription
            });
            await subscription.unsubscribe();
        }
    } catch (error) {
        console.error('Error unregistering push notification:', error);
        throw error;
    }
}

export async function checkPushPermission(): Promise<NotificationPermission> {
    if (!('Notification' in window)) {
        return 'denied';
    }
    return Notification.permission;
}

async function sendSubscriptionToServer(subscription: PushSubscription) {
    console.log('Sending subscription to server:', subscription);
    try {
        await httpClient.post('/push-subscription', subscription);
        console.log('Push subscription saved on server');
    } catch (error) {
        console.error('Error saving push subscription:', error);
    }
}

function urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export async function isPushAvailable(): Promise<boolean> {
    return 'serviceWorker' in navigator && 'PushManager' in window;
}

export async function getCurrentPushSubscription(): Promise<boolean> {
    try {
        if (!await isPushAvailable()) {
            return false;
        }

        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.getSubscription();
        
        if (!subscription) {
            return false;
        }

        // Проверяем наличие активной подписки на сервере
        const response = await httpClient.get('/push-subscription/status');
        return response.data.active;

    } catch (error) {
        console.error('Error checking push subscription:', error);
        return false;
    }
} 