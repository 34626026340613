import {clearAfterCreateContractEntities, clientPassportType, getSystemEntityById, SystemEntity} from "./SystemEntity";
import {getPostfixForEntity} from "./utils";
import {updateEntityInEditor} from "./updateEntityInEditor";
import {entityById} from "./systemEntityValueByKeyword";
import _ from "lodash";
import notifyUser from "./notifyUser";
import {arrayMove} from "./arrayMove";
import {string} from "mathjs";
import {$moment} from "../funcs";

export function cleanKeyword(text: string): string {
    if (!text) return '';
    return text.replace(/[<>"'&]/g, '');
}

export function updateEntityInList(entities: any[], keyword: string, value: string): any[] {
    for (const entity of entities) {
        if (entity.keyword == keyword) {
            entity.value = value
        }
    }
    return entities
}

export function entityByKeyword(entities: any[], keyword: string, oldKeyword: string | null = null): any | undefined {
    if (keyword.length == 0) {
        return
    }
    for (const entity of entities) {
        if (entity.keyword == keyword || entity.keyword == oldKeyword) {
            return entity
        }
    }
}

export function fullEntityValue(entity: any, separator: string = "\n"): string {
    if (!entity) {
        return ""
    }
    let value = entity.value ?? ""

    return value + additionalValuesString(entity, separator)
}

export function additionalValuesString(entity: any, separator: string = "\n"): string {
    if (!entity) {
        return ""
    }
    let result = ""
    if (entity.allowMultipleValues ?? false) {
        for (let additionalValue of entity.additionalValues ?? []) {
            if (additionalValue.length > 0) {
                let postfix = entity.postfix ? getPostfixForEntity({...entity, value: additionalValue}) : ''
                result += separator + additionalValue + postfix
            }
        }
    }
    return result
}

export function clearedEntitiesTemplate(template: any) {
    let entities = [...template.entities]
    let system_entities = [...template.system_entities]
    for (let entity of entities) {
        entity.value = entity.defaultValue ?? ""
        entity.additionalValues = []
        entity.postfix = ""
        updateEntityInEditor(entity)
    }

    for (let entity of clearAfterCreateContractEntities) {
        let entityToClear = entityById(system_entities, entity.id)
        if (entityToClear) {
            if (entityToClear.id == clientPassportType.id) {
                entityToClear.value = entityToClear.selectedValues[0]
            } else {
                entityToClear.value = ""
                entityToClear.postfix = ""
            }
        }
    }

    return {
        ...template,
        entities: entities,
        system_entities: system_entities
    }
}


export function getEntities(contract: any, isSystem: boolean): any[] {
    let path = isSystem ? 'system_entities' : 'entities'

    return [..._.get(contract, path, [])]
}

export function updateEntities(contract: any, isSystem: boolean, any_entities: any[]): any {
    let path = isSystem ? 'system_entities' : 'entities'

    let updatedContract = {...contract}
    _.set(updatedContract, path, any_entities)

    setTimeout(
        () => {
            for (let entity of any_entities) {
                updateEntityInEditor(entity, isSystem ? [] : updatedContract.entities)
            }
        },
        100
    )

    return updatedContract
}

export function updateEntity(contract: any, isSystem: boolean, entity: any): any {
    let any_entities = getEntities(contract, isSystem)
    const entityIndex = any_entities.findIndex(
        (e: any) => e.keyword === entity.keyword,
    );
    any_entities[entityIndex] = entity
    return updateEntities(contract, isSystem, any_entities)
}

export function addEntities(contract: any, isSystem: boolean, entities: any[]): any {
    let any_entities = getEntities(contract, isSystem)
    for (const entity of entities) {
        const cleanedKeyword = cleanKeyword(entity.keyword);
        entity.keyword = cleanedKeyword;
        
        const entityIndex = any_entities.findIndex(
            (e: any) => e.keyword === cleanedKeyword,
        );
        if (entityIndex != -1) {
            any_entities[entityIndex] = entity
        } else {
            any_entities.push(entity)
        }
        // console.log("addEntities", entity)
    }
    return updateEntities(contract, isSystem, any_entities)
}

export function deleteEntities(contract: any, isSystem: boolean, keywords: string[]): any {
    let any_entities = getEntities(contract, isSystem)
    const cleanedKeywords = keywords.map(k => cleanKeyword(k));
    
    if (isSystem) {
        for (let any_entity of any_entities) {
            for (let keyword of keywords) {
                if (keyword == any_entity.keyword) {
                    any_entity.hidden = true
                }
            }
        }
    } else {
        any_entities = any_entities.filter((entity: any) => !keywords.includes(entity.keyword))
    }

    return updateEntities(contract, isSystem, any_entities)
}
export function renameEntity(contract: any, oldKeyword: string, newKeyword: string): any {
    if (!oldKeyword || oldKeyword.length == 0) {
        return
    }
    
    const cleanedKeyword = cleanKeyword(newKeyword);
    
    let entities = [...contract.entities]
    for (let index = 0; index < entities.length; index++) {
        let entity = entities[index]
        if (entity.keyword == oldKeyword) {
            entity.keyword = cleanedKeyword
            contract.body = contract.body.replace(`{{${oldKeyword}}}`, `{{${cleanedKeyword}}}`)
            contract.body = contract.body.replace(`<<${oldKeyword}>>`, `<<${cleanedKeyword}>>`)
        } else {
            if (entity.type == "Формула" && entity.formula.includes(oldKeyword)) {
                entity.formula = entity.formula.replace(oldKeyword, cleanedKeyword)
            }
        }
        entities[index] = entity
    }
    return updateEntities(contract, false, entities)
}

export function setOrderForEntity(contract: any, keyword: string, order: number): any {
    let entities = [...contract.entities]
    let oldIndex = entities.findIndex((entity: any) => entity.keyword === keyword)
    if (oldIndex >= 0) {
        arrayMove(entities, oldIndex, order)

        let updatedContract = {...contract}
        updatedContract.entities = entities
        return updatedContract
    } else {
        return contract
    }
}

export function passportIssuedAtTitle(issuedAt: any): string {
    if (!issuedAt) {
        return ""
    }
    console.log("passportIssuedAtTitle", issuedAt)
    // В структуре user.passport ожидаем ISO дату
    let date = $moment(issuedAt, "YYYY-MM-DD")
    if (!date.isValid()) {
        date = $moment(issuedAt, "DD.MM.YYYY")
    }

    if (date.isValid()) {
        return date.format("DD.MM.YYYY")
    } else {
        return issuedAt.toString()
    }
}

export function sortArrayByOrder(array: any[]): any[] {
    return array.sort((a: any, b: any) => {
        if (a.order > b.order) {
            return 1
        } else if (a.order < b.order) {
            return -1
        }
        return 0
    })
}