import {contractSystemEntity} from "./systemEntityValueByKeyword";
import _ from "lodash";

export type SystemEntity = {
    type: SystemEntityType,
    keyword: string,
    id: string,
    editable: boolean,
    hidden: boolean,
    userPath: string,
    value?: string | boolean,
    validationRule?: string,
    computed?: boolean,
    selectedValues?: string[],
    allowedValues?: string[],
    onlyFromCamera?: boolean
}

export enum SystemEntityType {
    List = "Список",
    Text = "Текст",
    Date = "Дата",
    Photo = "Фото",
    Formula = "Формула",
    Signature = "Подпись"
}

export enum PassportType {
    russianPassport = "Паспорт гражданина РФ",
    russianInternationalPassport = "Загран. паспорт",
    belarusianPassport = "Паспорт гражданина Республики Беларусь",
    foreignCountryPassport = "Паспорт другого государства"
}

export const allPassportTypes: PassportType[] = [
    PassportType.russianPassport, PassportType.russianInternationalPassport, PassportType.belarusianPassport, PassportType.foreignCountryPassport
]

export function getSystemEntityById(id: string): SystemEntity | null {
    for (let entity of allSystemEntities) {
        if (entity.id == id) {
            return entity
        }
    }
    return null
}

export function getSystemEntityByKeyword(id: string): SystemEntity | null {
    for (let entity of allSystemEntities) {
        if (entity.id == id) {
            return entity
        }
    }
    return null
}

export let clientRole: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Тип аккаунта",
    id: "client_role",
    editable: true,
    hidden: true,
    userPath: "role"
}

export let storeClientData: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Хранение персональных данных",
    id: "store_client_data",
    editable: true,
    hidden: true,
    userPath: "store_client_data",
    value: true
}

// FIO
export let clientLastName: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Фамилия клиента",
    id: "client_last_name",
    editable: true,
    hidden: true,
    userPath: "last_name",
    validationRule: "lastName"
}

export let clientFIO: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "ФИО клиента",
    id: "client_fio",
    editable: false,
    hidden: true,
    userPath: "",
    validationRule: "",
    computed: true
}

export let firstSigner: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Исполнитель",
    id: "first_signer",
    editable: false,
    hidden: true,
    userPath: "",
    validationRule: "",
    computed: true
}

export let targetUser: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Клиент",
    id: "target_user",
    editable: false,
    hidden: true,
    userPath: "",
    validationRule: "",
    computed: true
}

export let clientFirstName: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Имя клиента",
    id: "client_first_name",
    editable: true,
    hidden: true,
    userPath: "first_name",
    validationRule: "firstName"
}

export let clientMiddleName: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Отчество клиента",
    id: "client_middle_name",
    editable: true,
    hidden: true,
    userPath: "middle_name",
}

// Contacts

export let clientEmail: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "E-Mail клиента",
    id: "client_email",
    editable: true,
    hidden: false,
    userPath: "email",
    validationRule: "email"
}

export let clientPhoneNumber: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Телефон клиента",
    id: "client_phone_number",
    editable: true,
    hidden: false,
    userPath: "phone_number",
    validationRule: "phoneNumber"
}

// Passport

export let clientPassportType: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Тип документа",
    id: "client_passport_type",
    editable: true,
    hidden: false,
    userPath: "passport.passport_type",
    validationRule: "notEmpty",
    selectedValues: [],
    allowedValues: allPassportTypes,
}

export let clientPassportNumber: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Паспорт клиента",
    id: "client_passport_number",
    editable: true,
    hidden: false,
    userPath: "passport.number",
    validationRule: "" // ruPassportNumber or anotherPassportNumber
}

export let clientPassportIssuedByCode: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Код подразделения",
    id: "client_passport_issued_by_code",
    editable: true,
    hidden: false,
    userPath: "passport.issued_by_code",
    validationRule: "ruPassportIssuedByCode"
}

export let clientPassportIssuedAt: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Дата выдачи паспорта клиента",
    id: "client_passport_issued_at",
    editable: true,
    hidden: false,
    userPath: "passport.issued_at",
    validationRule: "ruPassportIssuedDidAt"
}

export let clientPassportIssuedBy: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Кем выдан паспорт клиента",
    id: "client_passport_issued_by",
    editable: true,
    hidden: false,
    userPath: "passport.issued_by",
    validationRule: "notEmpty"
}

export let clientPassportRegistration: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Адрес прописки клиента",
    id: "client_passport_registration",
    editable: true,
    hidden: false,
    userPath: "passport.registration",
    validationRule: "notEmpty"
}

export let clientSignature: SystemEntity = {
    type: SystemEntityType.Signature,
    keyword: "Рукописная подпись клиента",
    id: "client_signature",
    editable: true,
    hidden: false,
    userPath: "passport.signature",
    validationRule: "notEmpty"
}

export let clientPassportPhoto: SystemEntity = {
    type: SystemEntityType.Photo,
    keyword: "Фото паспорта",
    id: "client_passport_photo",
    editable: true,
    hidden: true,
    userPath: "",
    validationRule: "notEmpty",
    onlyFromCamera: false
}

export let clientPassportSelfiePhoto: SystemEntity = {
    type: SystemEntityType.Photo,
    keyword: "Селфи с паспортом",
    id: "client_passport_selfie_photo",
    editable: true,
    hidden: false,
    userPath: "",
    validationRule: "notEmpty",
    onlyFromCamera: false
}

export let clientPassportRegistrationPhoto: SystemEntity = {
    type: SystemEntityType.Photo,
    keyword: "Фото прописки паспорта",
    id: "client_passport_registration_photo",
    editable: true,
    hidden: false,
    userPath: "",
    validationRule: "notEmpty",
    onlyFromCamera: false
}

// Entrepreneur

export let clientLawName: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Название организации клиента",
    id: "client_law_name",
    editable: true,
    hidden: false,
    userPath: "law_name",
    validationRule: "lawName"
}

export let clientLawAddress: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Адрес регистрации организации клиента",
    id: "client_law_address",
    editable: true,
    hidden: false,
    userPath: "law_address",
    validationRule: "lawAddress"
}

export let clientTaxId: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "ИНН клиента",
    id: "client_tax_id",
    editable: true,
    hidden: false,
    userPath: "tax_id",
    validationRule: "ruTaxId"
}

export let clientOGRN: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "ОГРН клиента",
    id: "client_ogrn",
    editable: true,
    hidden: false,
    userPath: "ogrn",
    validationRule: "ruOgrn"
}

// Bank

export let clientBankName: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Название банка клиента",
    id: "client_bank_name",
    editable: true,
    hidden: false,
    userPath: "bank_name",
    validationRule: "bankName"
}

export let clientBankAccount: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Расчетный счет банка клиента",
    id: "client_bank_account",
    editable: true,
    hidden: false,
    userPath: "bank_account",
    validationRule: "ruBankAccount"
}

export let clientCorrAccount: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Корр. счет банка клиента",
    id: "client_corr_account",
    editable: true,
    hidden: false,
    userPath: "corr_account",
    validationRule: "ruBankCorrAccount"
}

export let clientBIC: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "БИК банка клиента",
    id: "client_bic",
    editable: true,
    hidden: false,
    userPath: "bic",
    validationRule: "bic"
}

export let contractNumber: SystemEntity = {
    type: SystemEntityType.Text,
    keyword: "Номер договора",
    id: "contract_number",
    editable: true,
    hidden: false,
    userPath: ""
}
export let currentDate: SystemEntity = {
    type: SystemEntityType.Date,
    keyword: "Текущая дата",
    id: "contract_date",
    editable: true,
    hidden: false,
    userPath: ""
}


export const accountRelatedEntities: SystemEntity[] = [
    clientRole, storeClientData
]

export const contractRelatedEntities: SystemEntity[] = [
    contractNumber, currentDate
]

// CUSTOMER

export const contactsEntities: SystemEntity[] = [
    clientEmail, clientPhoneNumber,
]

export const customerPersonalEntities: SystemEntity[] = [
    clientFIO, clientFirstName, clientLastName, clientMiddleName
]

export const passportEntities = [
    clientPassportType,

    clientPassportNumber,
    clientPassportIssuedByCode,
    clientPassportIssuedBy,
    clientPassportIssuedAt,
    clientPassportRegistration,

    clientSignature,

    clientPassportPhoto,
    clientPassportSelfiePhoto,
    clientPassportRegistrationPhoto,
]

export const customerAllEntities: SystemEntity[] = customerPersonalEntities
    .concat(passportEntities)

export function isCustomerEntity(entityId: string): boolean {
    return customerAllEntities
        .concat(contactsEntities)
        .map((entity) => entity.id)
        .includes(entityId)
}

// ENTREPRENEUR

export const entrepreneurPersonalEntities: SystemEntity[] = [
    clientLawAddress, clientLawName, clientOGRN, clientTaxId,
]

export const entrepreneurBankEntities: SystemEntity[] = [
    clientBIC, clientBankAccount, clientCorrAccount, clientBankName
]

export const entrepreneurAllEntities: SystemEntity[] = entrepreneurPersonalEntities
    .concat(entrepreneurBankEntities)


export function isEntrepreneurEntity(entityId: string): boolean {
    return entrepreneurAllEntities
        .concat(contactsEntities)
        .map((entity) => entity.id)
        .includes(entityId)
}

// ALL

export const allSystemEntities: SystemEntity[] = contractRelatedEntities
    .concat(accountRelatedEntities)

    .concat(customerPersonalEntities)
    .concat(passportEntities)

    .concat(entrepreneurPersonalEntities)
    .concat(entrepreneurBankEntities)

    .concat(contactsEntities)

export const clearAfterCreateContractEntities: SystemEntity[] = customerPersonalEntities
    .concat(passportEntities)

    .concat(entrepreneurPersonalEntities)
    .concat(entrepreneurBankEntities)

    .concat(contactsEntities)
    .concat(contractRelatedEntities)

export function bulkUpdateSystemEntities(contract: any, entities: SystemEntity[], path: string, value: any): any[] {
    return entities.map((systemEntity: SystemEntity) => {
        let contractEntity = contractSystemEntity(contract, systemEntity.id) ?? systemEntity
        _.set(contractEntity, path, value)
        return contractEntity
    })
}