import {ReactComponent as Actions} from "../Icons/size24/ActionsHorizontal.svg";

import {connect} from "react-redux";
import React, {useState} from "react";
import {ReactComponent as Close} from "../Icons/size24/Close.svg";
import {ReactComponent as Delete} from "../Icons/size24/Delete.svg";
import notifyUser, {notifyAboutError} from "../../methods/notifyUser";
import {ReactComponent as Copy} from "../Icons/size24/Copy.svg";
import downloadContract from "../../utils/funcs/downloadContract";
import {ReactComponent as DownloadLight} from "../Icons/size24/DownloadLight.svg";
import DeclineContractPopup from "./DeclineContractPopup";
import DeleteContractPopup from "./DeleteContractPopup";
import {CircularProgress, Tooltip} from "@mui/material";
import {ReactComponent as DownloadDark} from "../Icons/size24/DownloadDark.svg";
import {contractStatus, ContractStatus} from "../../methods/ContractStatus";
import {contractLink} from "../../utils/funcs/getContractLink";
import MenuButton from "../../methods/MenuButton";
import {httpClient} from "../../funcs";
import {useRoute} from "react-router5";
import {Cancel, DoDisturb, MoreVert, NoteAdd, Queue} from "@mui/icons-material";
import {firstSignerTitle, targetUserTitle} from "../../methods/workWithContract";
import AppendixPopup from "./AppendixPopup";
import {DocumentType, getDocumentTypeText} from "../../methods/DocumentType";


export enum Flow {
    InListSelect = 1,
    InDetailsSelect,
    InDetailsButtons
}

type Props = {
    flow: Flow
    contract: any,
    isEditing: boolean,
    reload: Function,
    onCancelContract: Function,
    $store: any,
    $commitToStore: Function,
}

export let ContractActionsButton: any = function ContractActionsButton(props: Props) {
    const $router = useRoute()

    const documentType = props.contract.document_type
    const {flow, contract, isEditing, reload, onCancelContract, $store, $commitToStore} = props

    const [deletePopup, setDeletePopup] = useState(false);
    const [declinePopup, setDeclinePopup] = useState(false);
    const [appendixPopup, setAppendixPopup] = useState(false);

    const userSession = props.$store.userSession
    const creatorId = typeof contract?.created_by === 'object' ? contract?.created_by?._id : contract?.created_by
    const isCreator = creatorId == userSession?._id
    const firstSignerId = typeof contract?.first_signer === 'object' ? contract?.first_signer?._id : contract?.first_signer
    const isFirstSigner = firstSignerId == userSession?._id
    const targetUserId = typeof contract?.target_user === 'object' ? contract?.target_user?._id : contract?.target_user
    const isTargetUser = targetUserId == userSession?._id   
    const isInPackage = contract && "package_id" in contract && contract.package_id?.length > 0

    function canDelete() {
        if (documentType === DocumentType.CONTRACT && isInPackage) {
            return false;
        }

        return !isEditing &&
            contractStatus(contract) != ContractStatus.Signed &&
            contractStatus(contract) != ContractStatus.AwaitingConfirmation &&
            isCreator;
    }

    function canCancel(): boolean {
        if (!contract) {
            return false;
        }

        if (documentType === DocumentType.CONTRACT && isInPackage) {
            return false;
        }

        let status = contractStatus(contract);
        if (!userSession) {
            return status == ContractStatus.Awaiting;
        }

        if (isEditing) {
            return false;
        }

        switch (status) {
            case ContractStatus.Draft:
            case ContractStatus.Declined:
            case ContractStatus.Canceled:
                return false;

            case ContractStatus.AwaitingConfirmation:
            case ContractStatus.Signed:
                return isFirstSigner || isCreator;
            case ContractStatus.Awaiting:
                return true;
        }
        return false;
    }

    function canDownload() {
        if (!contract || documentType === DocumentType.PACKAGE) {
            return false
        }

        if (!contract.body || contract.body.length == 0) {
            return false
        }

        let status = contractStatus(contract)
        if (!status) {
            return false
        }

        let userSession = props.$store.userSession

        if (!userSession) {
            return status == ContractStatus.Awaiting
        }

        // @ts-ignore
        switch (status) {
            case ContractStatus.Draft:
            case ContractStatus.Declined:
            case ContractStatus.Canceled:
            case ContractStatus.AwaitingConfirmation:
                return isFirstSigner || isCreator

            case ContractStatus.Signed:
                return isFirstSigner || isCreator || isTargetUser

            case ContractStatus.Awaiting:
                return true
        }
        return false
    }

    function canDublicateOrMakeAppendix() {
        if (documentType === DocumentType.CONTRACT && isInPackage) {
            return false;
        }

        if (documentType === DocumentType.PACKAGE) {
            return false;
        }

        if (!userSession) {
            return false;
        }

        if (!isCreator) {
            return false;
        }

        if (contract.body_type == 'pdf') {
            return false;
        }

        return true;
    }

    function shouldAllowCopyLinkForFirstSigner(): boolean {
        if (documentType === DocumentType.CONTRACT && isInPackage) {
            return false;
        }

        return !(contract.first_signer && firstSignerId == creatorId);
    }

    function copyTooltipButton() {
        if (shouldAllowCopyLinkForFirstSigner()) {
            return  <Tooltip arrow={true} placement={"top"} title={"Скопировать ссылку для.."}>
                {
                    makeMenuForCopyLink()
                }
            </Tooltip>
        } else {
            return <Tooltip arrow={true} title={"Скопировать ссылку на договор"}>
                <button className="new bigIconed" onClick={(e) => {
                    navigator.clipboard.writeText(contractLink(contract._id, false, documentType))
                        .then(() => {
                            notifyUser(props, 'Ссылка скопирована', 'Теперь можете отправить ее вашему клиенту')
                        })
                }}>
                    <Copy className="icon dark"/>
                </button>
            </Tooltip>
        }
    }

    function copyForFirstSignerMenuItem() {
        return {
            icon: <Copy className="icon light"/>,
            title: `Скопировать ссылку для ${firstSignerTitle(contract)}`,
            onTap: () => {
                notifyUser(props, 'Ссылка скопирована', 'Теперь можете отправить ее первому подписанту')
            },
            isCopyToClipboard: true,
            copyText: contractLink(contract._id, true, documentType)
        }
    }

    function copyForSecondSignerMenuItem() {
        return {
            icon: <Copy className="icon light"/>,
            title: `Скопировать ссылку для ${targetUserTitle(contract)}`,
            onTap: () => {
                notifyUser(props, 'Ссылка скопирована', `Теперь можете отправить ее ${shouldAllowCopyLinkForFirstSigner() ? 'второму подписанту' : 'вашему клиенту'}`)
            },
            isCopyToClipboard: true,
            copyText: contractLink(contract._id, false, documentType)
        }
    }

    function makeMenuForCopyLink() {
        return <div>
            <MenuButton
                className={flow == Flow.InListSelect ? "new smallIconed" : "new bigIconed"}
                items={[
                    copyForFirstSignerMenuItem(),
                    copyForSecondSignerMenuItem()
                ]}
            >
                <Copy className="icon dark"/>
            </MenuButton>
        </div>
    }

    function declineContractButtonTitle() {
        return contractStatus(contract) == ContractStatus.Signed
            ? `Расторгнуть ${getDocumentTypeText(documentType)}`
            : `Отклонить ${getDocumentTypeText(documentType)}`
    }

    async function dublicateContract() {
        try {
            let result: any = await httpClient.post(`/contract/${contract._id}`)
            $router.router.navigate(
                'contract',
                {
                    contractId: result.data.contract_id
                }
            )
            location.reload()
        } catch (error) {
            notifyAboutError(props, error)
        }
    }

    function makeSelect() {
        return <div>
            <MenuButton
                className={flow == Flow.InListSelect ? "new smallIconed" : "new bigIconed"}
                items={[
                    canCancel() ? {
                        icon: <DoDisturb className="icon light"/>,
                        title: declineContractButtonTitle(),
                        onTap: () => {
                            setDeclinePopup(true)
                        }
                    } : null,
                    canDelete() ? {
                        icon: <Delete className="icon light"/>,
                        title: `Удалить ${getDocumentTypeText(documentType)}`,
                        onTap: () => {
                            setDeletePopup(true)
                        }
                    } : null,
                    shouldAllowCopyLinkForFirstSigner() ? copyForFirstSignerMenuItem() : null,
                    copyForSecondSignerMenuItem(),
                    canDownload() ? {
                        icon: <DownloadLight className="icon"/>,
                        title: "Скачать договор",
                        onTap: () => {
                            downloadContract(contract)
                        }
                    } : null,
                    canDublicateOrMakeAppendix() ? {
                        icon: <Queue className="icon light"/>,
                        title: "Дублировать договор",
                        onTap: async () => {
                            await dublicateContract()
                        }
                    } : null,
                    canDublicateOrMakeAppendix() ? {
                        icon: <NoteAdd className="icon light"/>,
                        title: "Создать приложение к этому документу",
                        onTap: () => {
                            setAppendixPopup(true)
                        }
                    } : null
                ]}
            >
                <MoreVert className="icon dark" />
            </MenuButton>
        </div>
    }

    function makeButtonsContainer() {
        return <div className="buttonsContainer">
            {
                canCancel() &&
                <Tooltip arrow={true} title={declineContractButtonTitle()}>
                    <button className="new bigIconed" onClick={() => {setDeclinePopup(true)}}>
                        <Close className="icon" style={{stroke: "#75809E"}} />
                    </button>
                </Tooltip>
            }

            {
                canDelete() &&
                <Tooltip arrow={true} title={`Удалить ${getDocumentTypeText(documentType)}`}>
                    <button className="new bigIconed" onClick={() => {
                        setDeletePopup(true)
                    }}>
                        <Delete className="icon dark"/>
                    </button>
                </Tooltip>
            }

            {
                copyTooltipButton()
            }

            {
                canDownload() &&
                <Tooltip arrow={true} title={"Скачать договор"}>
                    <button
                        className="new bigIconed"
                        onClick={(e) => {
                            e.stopPropagation()
                            downloadContract(contract)
                        }}
                    >
                        <DownloadDark className="icon"/>
                    </button>
                </Tooltip>
            }
            {
                canDublicateOrMakeAppendix() &&
                <Tooltip arrow={true} title={"Дублировать договор"}>
                    <button
                        className="new bigIconed"
                        onClick={(e) => {
                            e.stopPropagation()
                            dublicateContract()
                        }}
                    >
                        <Queue className="icon dark"/>
                    </button>
                </Tooltip>
            }
            {
                canDublicateOrMakeAppendix() &&
                <Tooltip arrow={true} title={"Создать приложение к этому документу"}>
                    <button
                        className="new bigIconed"
                        onClick={(e) => {
                            e.stopPropagation()
                            setAppendixPopup(true)
                        }}
                    >
                        <NoteAdd className="icon dark"/>
                    </button>
                </Tooltip>
            }
        </div>
    }

    function make() {
        switch (flow) {
            case Flow.InListSelect:
                return makeSelect()
            case Flow.InDetailsSelect:
                return makeSelect()
            case Flow.InDetailsButtons:
                return makeButtonsContainer()
        }
    }

    return <div>
        {
            make()
        }
        {
            declinePopup && contract._id &&
            <DeclineContractPopup
                reload={onCancelContract}
                contractStatus={contractStatus(contract)}
                contractId={contract._id}
                documentType={documentType}
                close={() => {
                    setDeclinePopup(false)
                }}
                $commitToStore={$commitToStore}
            />
        }

        {
            deletePopup &&
            <DeleteContractPopup
                reload={reload}
                contractId={contract._id}
                documentType={documentType}
                closePopup={() => {
                    setDeletePopup(false)
                }}
                $commitToStore={$commitToStore}
            />
        }

        {
            appendixPopup &&
            <AppendixPopup
                contractId={contract._id}
                close={() => {
                    setAppendixPopup(false)
                }}
                $commitToStore={$commitToStore}
            />
        }
    </div>
}

ContractActionsButton = connect((store) => ({ $store: store }), (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) }))(ContractActionsButton)