import Popup from "../../../components/Popup";
import {HelpView} from "../../Common/HelpView";
import React, {useState} from "react";
import {Close} from "@mui/icons-material";
import {useRoute} from "react-router5";
import DeclineContractPopup from "../DeclineContractPopup";
import {contractStatus} from "../../../methods/ContractStatus";

export let EditSignedContractPopup: any = function EditSignedContractPopup(props: any) {
    const $router = useRoute()
    const [declinePopup, setDeclinePopup] = useState(false);

    function navigateToAdditionalAgreement() {
        $router.router.navigate(
            'shared-template',
            {
                shareLinkId: "66faad1bf8c6fbc0f5af551b",
            }
        )
    }

    function userIsCreator() {
        return props.contract.created_by?._id == props.$store.userSession?._id
    }

    function subtitle() {
        return <div>
            Так как договор уже подписан, его <b>изменить нельзя</b>. Согласно закону, изменить условия в подписанном договоре можно подписав доп.
            соглашение или заново подписав новый договор (этот договор нужно будет расторгнуть).
            <br/>
            <br/>

            {
                userIsCreator() &&
                <>
                    <b>Чтобы подписать доп соглашение</b> - добавьте себе шаблон доп. соглашения, пропишите в нем как именно меняется договор и выставьте его на подпись
                    <button
                        className="new withTitle"
                        style={{marginTop: "10px"}}
                        onClick={navigateToAdditionalAgreement}
                    >
                        Посмотреть доп. соглашение
                    </button>

                    <br/>
                    <b>Чтобы расторгнуть этот договор и подписать новый</b> - нажмите на кнопку "Расторгнуть договор" в панели инструментов договора и выставьте договор заново
                    <button
                        className="new withTitle"
                        style={{marginTop: "10px"}}
                        onClick={() => {setDeclinePopup(true)}}
                    >
                        Расторгнуть договор
                    </button>
                </>
            }

            {
                !userIsCreator() &&
                <>
                    Вы можете попросить иницатора договора расторгнуть договор и выставить новый договор или выставить доп. соглашение к этому договору
                </>
            }


            <hr className={"newHR"}/>
            Стоить отметить, что из-за незначительных неточностей совсем не обязательно заново подписывать договор.
            Например, опечатка в ФИО или в паспортных данных не делает договор юридически ничтожным.
            Если опечатка в сумме договора или в других значимых условиях договора, то лучше договор подписать заново.
        </div>
    }

    if (declinePopup) {
        return <DeclineContractPopup
            reload={props.reload}
            contractStatus={contractStatus(props.contract)}
            contractId={props.contract._id}
            documentType={props.contract.document_type}
            close={() => {
                setDeclinePopup(false)
                props.onClose()
            }}
            $commitToStore={props.$commitToStore}
        />
    }

    return <Popup
        style={{zIndex: 999}}
        onClose={props.onClose}
    >
        <HelpView
            title={"Подписанный договор уже нельзя отредактировать"}
            subtitle={subtitle()}
        />
    </Popup>
}