import React from 'react';
import {useRoute} from "react-router5";
import errorImage from '../images/pages/error_page_image.jpg';
import { nError } from 'funcs';
import { supportLink } from 'methods/openInNewTab';
import { openInNewTab } from 'methods/openInNewTab';
import { Refresh } from '@mui/icons-material';

type Props = {
    error: any;
    customAction?: {
        text: string;
        onClick: () => void;
    };
    secondaryAction?: {
        text: string;
        onClick: () => void;
    };
}

export const ErrorPageView: React.FC<Props> = ({error, customAction, secondaryAction}) => {
    const $router = useRoute();
    
    const convertedError = nError(error)
    const title = convertedError.notification.title
    const content = convertedError.notification.content as string

    console.log("error:", error, "convertedError:", convertedError, "title:", title, "content:" , content)

    function openTelegramSupport() {
        openInNewTab(supportLink())
    }

    const getErrorInfo = () => {
        switch (convertedError?.error?.response?.status) {
            case 401:
                return {
                    title: 'Требуется авторизация',
                    description: 'Для доступа к этой странице необходимо войти в систему',
                    actionText: 'Войти',
                    action: () => $router.router.navigate('main')
                };
            case 403:
                return {
                    title: 'Нет доступа',
                    description: 'У вас нет прав для просмотра этой страницы',
                    actionText: 'На главную',
                    action: () => $router.router.navigate('main')
                };
            case 404:
                return {
                    title: 'Страница не найдена',
                    description: 'Возможно, документ был удален или перемещен',
                    actionText: 'На главную',
                    action: () => $router.router.navigate('main')
                };
            case 406:
                return {
                    title: 'Не правильная ссылка на документ',
                    description: 'Убедитесь, что вы ввели правильную ссылку на документ',
                    actionText: 'На главную',
                    action: () => $router.router.navigate('main')
                };
            case 500:
                return {
                    title: 'Что-то пошло не так',
                    description: 'Произошла внутренняя ошибка сервера. Попробуйте позже или обратитесь в техподдержку',
                    actionText: 'Обновить страницу',
                    action: () => window.location.reload(),
                    icon: <Refresh className="icon"/>,
                    secondaryActionText: 'Написать в техподдержку',
                    secondaryAction: () => openTelegramSupport()
                };
            default:
                return {
                    title: 'Ошибка соединения',
                    description: 'Не удалось загрузить данные. Проверьте подключение к интернету',
                    actionText: 'Попробовать снова',
                    action: () => window.location.reload(),
                    icon: <Refresh className="icon"/>,
                    secondaryActionText: 'Написать в техподдержку',
                    secondaryAction: () => openTelegramSupport()
                };
        }
    };

    const errorInfo = getErrorInfo();

    console.log("errorInfo:", errorInfo)
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '40px 20px',
            textAlign: 'center',
            height: '100%'
        }}>
            <img 
                src={errorImage} 
                alt="Error illustration"
                style={{
                    width: '100%',
                    maxWidth: '400px',
                    marginBottom: '32px'
                }}
            />
            
            <h1 style={{
                fontSize: '24px',
                fontWeight: 600,
                color: '#0E1F4D',
                marginBottom: '16px'
            }}>
                { title || errorInfo.title}
            </h1>
            
            <p style={{
                fontSize: '16px',
                color: '#75809E',
                marginBottom: '24px',
                maxWidth: '400px'
            }}>
                {content || errorInfo.description}
            </p>

            <div style={{
                display: 'flex',
                gap: '12px',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <button
                    className="new withTitle primary"
                    onClick={customAction?.onClick || errorInfo.action}
                >
                    <div className="buttonsContainer">
                        {errorInfo.icon}
                        <span>{customAction?.text || errorInfo.actionText}</span>
                    </div>
                </button>

                {(secondaryAction || errorInfo.secondaryAction) && (
                    <button
                        className="new withTitle secondary"
                        onClick={secondaryAction?.onClick || errorInfo.secondaryAction}
                    >
                        {secondaryAction?.text || errorInfo.secondaryActionText}
                    </button>
                )}
            </div>
        </div>
    );
}; 