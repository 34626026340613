import {connect} from "react-redux";
import {httpClient, nError} from "../../funcs";
import notifyUser from "../../methods/notifyUser";
import React, {useState} from "react";
import {useRoute} from "react-router5";
import {State} from "router5";
import {Params} from "router5/dist/types/base";
import EnterTextPopup from "../Common/EnterTextPopup";
import {Tooltip, useMediaQuery} from "@mui/material";
import ErrorView from "../Common/ErrorView";

import ConfirmationPopup from "../Common/ConfirmationPopup";
import MakeMassTemplatePopup from "../Templates/MakeMassTemplatePopup";

import {ReactComponent as Back} from "pages/Icons/size24/Back.svg"
import {ReactComponent as Actions} from "pages/Icons/size24/ActionsHorizontal.svg"
import {ReactComponent as Edit} from "pages/Icons/size24/Edit.svg"
import {ReactComponent as Preview} from "pages/Icons/size24/Preview.svg"
import ContractPreviewPopup from "./ContractPreviewPopup";
import {addValueToSpanTags} from "../../methods/addValueToSpanTags";
import {
    CleaningServices,
    ContentPasteGo,
    Queue, Share,
    Security,
    Lock
} from "@mui/icons-material";
import Filter9PlusIcon from '@mui/icons-material/Filter9Plus';
import {addSpanTagsToBody} from "../../methods/addSpanTagsToBody";
import {trackGoal} from "../../methods/trackGoal";
import MenuButton from "../../methods/MenuButton";
import ShareTemplatePopup from "../ShareTemplate/ShareTemplatePopup";
import {mobileMaxWidthMediaQuery} from "../../methods/utils";
import EditTemplatePermissionsPopup from "./EditTemplatePermissionsPopup";

"../Icons/size24/ActionsHorizontal.svg"
type Props = {
    prevRoute: State | null,
    template: any,
    saveDisabled: boolean,
    saveContract: Function,
    isChanging: string
    saveNewName: Function,
    saveNewComment: Function,
    saveTemplateAsNew: Function,
    clear: Function,
    makeMassTemplate: Function,
    errorMessage: string | null,
    updateTemplateSettings: Function,
    reloadTemplate: Function,
    updateTemplate: Function,
    $store: any
}

interface prevParams {
    [name: string]: [string, Params];
}

const params: prevParams = {
    "new-contract": ["new-contract", {act: "list"}],
    contract: ["signed-contracts", {}],
};

let ContractHeader: any = function ContractHeader(props: Props) {
    const $router = useRoute();
    const {prevRoute, template, saveDisabled, saveContract, isChanging, saveNewName, saveNewComment, saveTemplateAsNew, clear, makeMassTemplate, updateTemplateSettings} = props
    const [showEditName, setShowEditName] = useState(false)
    const [showEditComment, setShowEditComment] = useState(false)
    const [showSaveAs, setShowSaveAs] = useState(false)
    const [errorClosed, setErrorClosed] = useState(false);
    const [showContractPreview, setShowContractPreview] = useState(false)
    const [clearPopup, setClearPopup] = useState(false);
    const [showMassContract, setShowMassContract] = useState(false);
    const [showShareTemplatePopup, setShowShareTemplatePopup] = useState(false);
    const [showPermissionsPopup, setShowPermissionsPopup] = useState(false);

    const mobile = useMediaQuery(mobileMaxWidthMediaQuery)


    function back() {
        if (props.updateTemplate) {
            props.updateTemplate()
        }

        if (prevRoute) {
            if (prevRoute.path !== $router.route.path) {
                $router.router.navigate('new-contract', {
                    act: 'list'
                }, { reload: false })
            } else {
                const route = params[prevRoute.name];
                $router.router.navigate(route[0], route[1]);
            }
        } else {
            $router.router.navigate('new-contract', {
                act: 'list'
            }, { reload: false })
        }
    }

    // Delete template function

    const [deletePopup, setDeletePopup] = useState(false);
    async function deleteTemplate() {
        try {
            await httpClient.delete(`/template/${template._id}`);

            back()

            // Notify user
            notifyUser(props, "Шаблон удалён")
        } catch (error) {
            const $error = nError(error);
            notifyUser(props, $error.notification.title)
        } finally {
            setDeletePopup(false)
        }
    }

    function makeBackButton() {
        return <button
            className="new bigIconed"
            onClick={() => {
                back()
            }}
        >
            <Back className="icon dark"/>
        </button>
    }

    function mobileButtonsContainer(template: any) {
        return <div className="navbar">
            <div style={{padding: "20px 15px 15px 15px"}}>
                <div className="titleContainer">
                    <div className="buttonsContainer">
                        <button
                            className="new"
                            style={{width: "16px", height: "16px"}}
                            onClick={() => {
                                back()
                            }}
                        >
                            <Back className="smallIcon" style={{fill: "#75809E"}}/>
                        </button>
                        <div className="navbarHeader">
                            К списку шаблонов
                        </div>
                    </div>

                    <div className="serviceMessage">
                        {isChanging === "saving" ? "cохраняем..." : ""}
                        {isChanging === "saved" ? "cохранено" : ""}
                    </div>
                </div>
                <hr className="newHR"/>
                <div
                    className="titleContainer _wa"
                >
                    {
                        makeActionsButton()
                    }

                    {
                        saveButton()
                    }
                </div>
            </div>
        </div>
    }

    function saveButton() {
        return <div className="sign">
            <div className="buttonsContainer" style={{gap: "0px"}}>
                <button className="new sign left" onClick={() => {saveContract(true)}}>
                    Подписать и отправить
                </button>
                <div>
                    <div style={{width: "1px", backgroundColor: "#75CDFF", height: "27px"}}/>
                </div>
                <MenuButton
                    className="new sign right"
                    items={[
                        {
                            "icon": <ContentPasteGo sx={{fill: "#D4D8E1"}}/>,
                            "title": "Не подписывая выставить на подпись третьим лицам.",
                            onTap: () => {
                                trackGoal('create_for_first_signer')
                                saveContract(false)
                            }
                        },
                        {
                            "icon": <Filter9PlusIcon sx={{fill: template?.is_mass_template ? '#75809E' : "#D4D8E1"}}/>,
                            "title": "Выставить для массового подписания...",
                            onTap: () => {
                                trackGoal('open_mass_template')
                                setShowMassContract(true)
                            }
                        }
                    ]}
                >
                    <Actions className="icon white"/>
                </MenuButton>
            </div>
        </div>
    }
    function desktopButtonsContainer(template: any) {
        return <div className="titleContainer">
            <div className="buttonsContainer">
                {makeBackButton()}

                <Tooltip arrow={true} title={"Дублировать шаблон"}>
                    <button className="new bigIconed" onClick={() => {setShowSaveAs(true)}}>
                        <Queue className="icon dark"/>
                    </button>
                </Tooltip>

                <Tooltip arrow={true} title={"Очистить все поля шаблона"}>
                    <button className="new bigIconed" onClick={() => {setClearPopup(true)}}>
                        <CleaningServices sx={{fill: "#75809E"}}/>
                    </button>
                </Tooltip>

                {/*<Tooltip arrow={true} title={"Выставить для массового подписания"}>*/}
                {/*    <button*/}
                {/*        className="new bigIconed"*/}
                {/*        onClick={() => {*/}
                {/*            trackGoal('open_mass_template')*/}
                {/*            setShowMassContract(true)*/}
                {/*        }}*/}
                {/*        style={{backgroundColor: template?.is_mass_template ? "#75809E" : '#EAECF3'}}*/}
                {/*    >*/}
                {/*        <Filter9PlusIcon sx={{fill: template?.is_mass_template ? '#EAECF3' : "#75809E"}}/>*/}
                {/*    </button>*/}
                {/*</Tooltip>*/}

                <Tooltip arrow={true} title={"Поделиться шаблоном"}>
                    <button
                        className="new bigIconed"
                        onClick={() => {setShowShareTemplatePopup(true)}}
                        style={{backgroundColor: '#EAECF3'}}
                    >
                        <Share sx={{fill: "#75809E"}}/>
                    </button>
                </Tooltip>

                {template.can_edit_permissions && (
                    <Tooltip arrow={true} title={"Редактировать разрешения"}>
                        <button
                            className="new bigIconed"
                            onClick={() => setShowPermissionsPopup(true)}
                            style={{backgroundColor: '#EAECF3'}}
                        >
                            <Lock sx={{fill: "#75809E"}}/>
                        </button>
                    </Tooltip>
                )}
            </div>
            <div className="buttonsContainer">
                <div className="serviceMessage">
                    {isChanging === "saving" ? "cохраняем..." : ""}
                    {isChanging === "saved" ? "cохранено" : ""}
                </div>
                <Tooltip arrow={true} title={"Предпросмотр договора"}>
                    <button className="new bigIconed" onClick={() => {
                        trackGoal('open_contract_preview')
                        setShowContractPreview(true)
                    }}>
                        <Preview className="icon dark"/>
                    </button>
                </Tooltip>

                {
                    saveButton()
                }
            </div>
        </div>
    }

    function makeActionsButton() {
        return <div>
            <MenuButton
                className="new bigIconed"
                items={[
                    {
                        "icon": <Queue className="icon light"/>,
                        "title": "Дублировать шаблон",
                        onTap: () => setShowSaveAs(true)
                    },
                    {
                        "icon": <CleaningServices sx={{fill: "#D4D8E1"}}/>,
                        "title": "Очистить все поля шаблона",
                        onTap: () => setClearPopup(true)
                    },
                    {
                        "icon": <Share className="icon light"/>,
                        "title": "Поделиться шаблоном с коллегами",
                        onTap: () => {
                            trackGoal('share_template')
                            setShowShareTemplatePopup(true)
                        }
                    },
                    template.can_edit_permissions && {
                        "icon": <Lock sx={{fill: "#D4D8E1"}}/>,
                        "title": "Редактировать разрешения",
                        onTap: () => setShowPermissionsPopup(true)
                    },
                ].filter(Boolean)}
            >
                <Actions className="icon dark" />
            </MenuButton>
        </div>
    }

    return <div>
        {
            mobile ? mobileButtonsContainer(template) : desktopButtonsContainer(template)
        }

        <div className="buttonsContainer" style={{marginTop: mobile ? "148px" : "25px"}}>
            <div className="contractTitle">
                {template.name}
            </div>
            {template.can_edit_template &&
                <button
                    className="new smallIconed"
                    onClick={() => {
                        setShowEditName(true)
                    }}
                >
                    <Edit className="icon dark"/>
                </button>
            }
        </div>
        <div className="buttonsContainer" style={{marginTop: "15px"}}>
            <div className="Body_15 comment">
                {template.comment?.length > 0 ? template.comment : "Добавьте комментарий для этого шаблона"}
            </div>
            {template.can_edit_template &&
                <button
                    className="new smallIconed"
                    onClick={() => {
                        setShowEditComment(true)
                    }}
                >
                    <Edit className="icon dark"/>
                </button>
            }
        </div>
        {
            !errorClosed && props.errorMessage && <ErrorView message={props.errorMessage} onClose={() => {setErrorClosed(true)}}/>
        }
        {
            showEditName &&
            <EnterTextPopup
                title={"Введите новое название для шаблона"}
                placeholder={"Название шаблона"}
                oldText={template.name}
                onClose={() => {
                    setShowEditName(false)
                }}
                canSaveEmpty={false}
                saveNewName={(newName: string) => {
                    setShowEditName(false)
                    saveNewName(newName)
                }}
            />
        }
        {
            showEditComment &&
            <EnterTextPopup
                title={"Введите комментарий для шаблона. Комментарий виден только вам"}
                placeholder={"Комментарий"}
                oldText={template.comment ?? ""}
                onClose={() => {
                    setShowEditComment(false)
                }}
                canSaveEmpty={true}
                saveNewName={(comment: string) => {
                    setShowEditComment(false)
                    saveNewComment(comment)
                }}
            />
        }
        {
            showSaveAs &&
            <EnterTextPopup
                title={"Введите название для нового шаблона"}
                placeholder={"Название нового шаблона"}
                oldText={template.name}
                onClose={() => {
                    setShowSaveAs(false)
                }}
                canSaveEmpty={false}
                saveNewName={(newName: string) => {
                    setShowSaveAs(false)
                    saveTemplateAsNew(newName)
                }}
            />
        }
        {
            deletePopup &&
            <ConfirmationPopup
                title="Уверены, что хотите удалить шаблон? Это действие безвозратно!"
                onClose={() => {setDeletePopup(false)}}
                onConfirm={() => {
                    setDeletePopup(false)
                    deleteTemplate()
                }}
            />
        }
        {
            showContractPreview &&
            <ContractPreviewPopup
                template={template}
                onClose={() => {setShowContractPreview(false)}}
                saveContract={() => {
                    setShowContractPreview(false)
                    saveContract(true)
                }}
                $store={props.$store}
            />
        }
        {
            clearPopup &&
            <ConfirmationPopup
                title="Уверены, что хотите очистить все поля от введенных значений?"
                onClose={() => {setClearPopup(false)}}
                onConfirm={() => {
                    setClearPopup(false)
                    clear()
                }}
            />
        }
        {
            showMassContract &&
            <MakeMassTemplatePopup
                template={template}
                onClose={() => {setShowMassContract(false)}}
                onConfirm={(dataForMassTemplate: object) => {
                    setShowMassContract(false)
                    makeMassTemplate(dataForMassTemplate)
                }}
            />
        }
        {
            showShareTemplatePopup &&
            <ShareTemplatePopup
                template={template}
                close={() => {setShowShareTemplatePopup(false)}}
            />
        }
        {
            showPermissionsPopup &&
            <EditTemplatePermissionsPopup
                template={template}
                onClose={() => setShowPermissionsPopup(false)} 
                onUpdate={() => props.reloadTemplate()}
            />
        }
    </div>
}

ContractHeader = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(ContractHeader)

export default ContractHeader
