import React, {useState, useEffect} from 'react';
import Popup from "../../components/Popup";
import {httpClient} from "../../funcs";
import {notifyUser, notifyAboutError} from "../../methods/notifyUser";
import {Checkbox, FormControlLabel, FormGroup, useMediaQuery} from "@mui/material";
import {ReactComponent as CheckboxIcon} from "pages/Icons/size24/Checkbox.svg";
import {ReactComponent as CheckboxChecked} from "pages/Icons/size24/CheckboxChecked.svg";
import {ReactComponent as CheckboxDisabledChecked} from "pages/Icons/size24/CheckboxDisabledChecked.svg";
import {connect} from "react-redux";
import { PageLoader } from 'pages/Contracts/ContractList/PageLoader';
import { mobileMaxWidthMediaQuery } from 'methods/utils';

type User = {
    _id: string;
    type: 'role';
    granted_to?: {
        phone_number: string;
        first_name?: string;
        last_name?: string;
    };
    status: string;
}

type Permission = {
    id: string;
    label: string;
    description: string;
}

const AVAILABLE_PERMISSIONS: Permission[] = [
    {
        id: 'available',
        label: 'Базовый доступ',
        description: 'Можно только выставлять договоры на подпись'
    },
    {
        id: 'edit_template',
        label: 'Редактирование шаблона',
        description: 'Возможность изменять текст шаблона и поля'
    },
    {
        id: 'edit_contract',
        label: 'Редактирование договора',
        description: 'Возможность редактировать договор выставленный на основе этого шаблона'
    }
];

interface Props {
    template: any;
    onClose: () => void;
    $store?: any;
    $commitToStore?: (data: any) => void;
    onUpdate?: () => void;
}

function EditTemplatePermissionsPopup({template, onClose, $store, $commitToStore, onUpdate}: Props) {
    const [roles, setRoles] = useState<User[]>([]);
    const [permissions, setPermissions] = useState<{[key: string]: string[]}>({});
    const [loading, setLoading] = useState(true);
    const mobile = useMediaQuery(mobileMaxWidthMediaQuery);

    useEffect(() => {
        loadRoles();
    }, []);

    const loadRoles = async () => {
        try {
            const response = await httpClient.get('/get-user-list');
            const activeRoles = response.data.users.filter((user: User) => 
                user.type === 'role' && user.status === 'CONFIRMED'
            );
            setRoles(activeRoles);
            
            if (!template.permissions) {
                const allPermissions = AVAILABLE_PERMISSIONS.map(p => p.id);
                const defaultPermissions = activeRoles.reduce((acc: any, role: User) => {
                    acc[role._id] = allPermissions;
                    return acc;
                }, {});
                setPermissions(defaultPermissions);
            } else {
                setPermissions(template.permissions);
            }
        } catch (error) {
            notifyAboutError({$store, $commitToStore}, error);
        } finally {
            setLoading(false);
        }
    };

    const handlePermissionChange = (roleId: string, permissionId: string) => {
        const allPermissions = AVAILABLE_PERMISSIONS.map(p => p.id);
        const currentPermissions = permissions[roleId] || [...allPermissions];
        let newPermissions;

        if (permissionId === 'available') {
            newPermissions = currentPermissions.includes(permissionId) ? [] : ['available'];
        } else {
            if (currentPermissions.includes(permissionId)) {
                newPermissions = currentPermissions.filter(p => p !== permissionId);
            } else {
                newPermissions = [...currentPermissions, permissionId];
                if (!currentPermissions.includes('available')) {
                    newPermissions.push('available');
                }
            }
        }

        setPermissions({
            ...permissions,
            [roleId]: newPermissions
        });
    };

    const handleSave = async () => {
        try {
            await httpClient.post(`/contract-template/${template._id}/permissions`, permissions);
            notifyUser({$commitToStore}, "Разрешения успешно обновлены");
            if (onUpdate) {
                onUpdate();
            }
            onClose();
        } catch (error) {
            notifyAboutError({$commitToStore}, error);
        }
    };

    const getUserDisplayName = (user: User) => {
        if (!user.granted_to) return "Неизвестный пользователь";
        
        const { first_name, last_name, phone_number } = user.granted_to;
        if (first_name || last_name) {
            return `${first_name || ''} ${last_name || ''} (${phone_number})`.trim();
        }
        return phone_number;
    };

    return (
        <Popup maxWidth="650px" onClose={onClose}>
            <div style={{width: mobile ? "100%" : "500px"}}>
                <div className='commonTitle' style={{marginBottom: '10px'}}>
                    Редактирование разрешений
                </div>
                {loading ? (
                    <PageLoader/>
                ) : (
                    <>
                        {roles.map(role => (
                            <div key={role._id} style={{marginBottom: '20px'}} className="templateViewContainer disabledHover">
                                <div className='commonLabel' style={{marginBottom: '10px'}}>
                                    Сотрудник: {getUserDisplayName(role)}
                                </div>
                                <FormGroup>
                                    {AVAILABLE_PERMISSIONS.map(permission => {
                                        const currentPermissions = permissions[role._id] || [];
                                        const hasAvailable = currentPermissions.includes('available');
                                        
                                        if (permission.id !== 'available' && !hasAvailable) {
                                            return null;
                                        }

                                        return <div className="titleContainer" key={permission.id} style={{marginTop: '10px'}}>
                                                    <div>
                                                        <div className="Subheader_17">
                                                            {permission.label}
                                                        </div>
                                                        <div className="commonSubtitle">
                                                            {permission.description}
                                                        </div>
                                                    </div>

                                                    <Checkbox
                                                        icon={<CheckboxIcon className="icon"/>}
                                                        checkedIcon={<CheckboxChecked className="icon"/>}
                                                        checked={currentPermissions.includes(permission.id)}
                                                        onChange={() => handlePermissionChange(role._id, permission.id)}
                                                    />
                                        </div>
                                    })}
                                </FormGroup>
                            </div>
                        ))}

                        <hr className="newHR"/> 

                        <div>
                            <button className="new withTitle _wa" onClick={handleSave}>
                                Сохранить
                            </button>
                            <button className="new withTitle secondary _wa" onClick={onClose} style={{marginTop: '10px'}}>
                                Отмена
                            </button>
                        </div>
                    </>
                )}
            </div>
        </Popup>
    );
}

export default connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(EditTemplatePermissionsPopup); 