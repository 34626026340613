import {connect} from "react-redux";
import {commonSelect, commonTextField, TextareaStyled, TextFieldStyled} from "../Styles/CommonCSSProperties";
import {$moment} from "../../funcs";
import FormControl from "@mui/material/FormControl";
import {Autocomplete, CircularProgress} from "@mui/material";
import {ReactComponent as Upload} from "../Icons/size24/Upload.svg";
import {ReactComponent as RefreshFilled} from "../Icons/size24/RefreshFilled.svg";
import {ReactComponent as Delete} from "../Icons/size24/Delete.svg";
import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import imageCompression from "browser-image-compression";
import notifyUser from "../../methods/notifyUser";
import {formatBytes} from "../../methods/formatBytes";
import {DatePicker, PickersDay} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import updateLocale from 'dayjs/plugin/updateLocale'
import {IMaskInput} from "react-imask";
import {MobileInputMask} from "../../components/elements/InputWithMask";
import {trackGoal} from "../../methods/trackGoal";
import {clientPassportNumber} from "../../methods/SystemEntity";
import { addWatermark } from "../../methods/watermarkImage";
import { SignaturePopup } from "./SignaturePopup";
import { Refresh } from "@mui/icons-material";

type Props = {
    myRef: {current: {}},
    entity: any,
    disabled?: boolean,
    updateEntity: Function,
    setImageStringTag: boolean,
    maxWidthOrHeight: number,
}


function defaultDateValue(entity: any) {
    let format = 'LL'
    if (dayjs(entity.value, 'DD.MM.YYYY').isValid()) {
        format = 'DD.MM.YYYY'
    }
    let date = $moment(entity.value, format)
    if (date) {
        return dayjs(date.format("MM/DD/YYYY"))
    } else {
        return null
    }
}

function makeDatePicker(input: any, isEditing: any, setIsEditing: any, entity: any, updateEntity: any, disabled: boolean = false) {
    dayjs.extend(updateLocale)
    dayjs.updateLocale('en', {
        weekStart: 1,
    })

    return <DatePicker
        inputRef={input}

        slots={{
            // Override default <ActionBar /> with a custom one
            day: PickersDay,
        }}

        dayOfWeekFormatter={(day) => {
            switch (day) {
                case "Su":
                    return "Вс"
                case "Mo":
                    return "Пн"
                case "Tu":
                    return "Вт"
                case "We":
                    return "Ср"
                case "Th":
                    return "Чт"
                case "Fr":
                    return "Пт"
                case "Sa":
                    return "Сб"
                default:
                    return day
            }
        }}
        slotProps={{
            // pass props `actions={['clear']}` to the actionBar slot
            day: { showDaysOutsideCurrentMonth: true },
        }}
        disabled={disabled}
        format={"DD.MM.YYYY"}
        value={defaultDateValue(entity)}
        sx={{
            border: isEditing ? "1px solid #75809E !important" : "none",
            background: isEditing ? "white !important" : "#EAECF3",
            padding: isEditing ? "1.5px !important" : "2.5px",
            width: "100%"
        }}
        onOpen={() => {
            setIsEditing(true)
        }}
        onClose={() => {
            setIsEditing(false)
        }}
        onChange={(value: any) => {
            value = value ?? ""
            let date = $moment(`${value.$D}.${value.$M + 1}.${value.$y}`, "DD.MM.YYYY")
            let formattedDate = ""
            if ((date.toString() === "Invalid Date")) {
                formattedDate = ""
            } else {
                formattedDate = date.isValid() ? date.format('LL') : ""
            }
            updateEntity(formattedDate)
        }}
    />
}


let EntityInput: any = function EntityInput(props: Props) {
    const {myRef, disabled, entity, updateEntity, setImageStringTag=true, maxWidthOrHeight=1024} = props

    useImperativeHandle(myRef,
        () => focusInput,
        []
    )

    const [isEditing, setIsEditing] = useState(false)

    const [fileName, setFileName] = useState("");
    const [loading, setLoading] = useState(false);

    const photoUploadRef = useRef<HTMLInputElement>(null);
    const input = useRef<HTMLInputElement>(null);
    const textInput = useRef<HTMLTextAreaElement>(null);
    const maskInput = useRef(null);

    const [result, setResult] = useState<any>(null)

    useEffect(() => {
        if (result) {
            updateEntity(result)
        }
    }, [result])

    async function processPhotoInput(files: FileList | null) {
        setLoading(true)
        try {
            const file = files && files.length ? files[0] : null;
            
            if (!file) {
                throw new Error('Файл не выбран');
            }

            console.log('Исходный файл:', {
                name: file.name,
                type: file.type,
                size: file.size
            });

            if (!file.type.startsWith('image/')) {
                throw new Error('Неверный формат файла. Пожалуйста, загрузите изображение');
            }

            let name = file.name;
            setFileName(name);
            
            await getBase64(file);
        } catch (error) {
            console.error('Детальная ошибка при обработке фото:', error);
            notifyUser(props, 'Не удалось загрузить фото. Попробуйте сделать новое фото или выбрать другой файл');
            trackGoal("take_photo_error", { 
                photo_error: error instanceof Error ? error.message : 'unknown_error'
            });
        } finally {
            setLoading(false);
        }
    }

    async function getBase64(file: File) {
        try {
            console.log('Начало сжатия файла');
            const compressedFile = await imageCompression(file, {
                maxSizeMB: 0.3,
                maxWidthOrHeight: maxWidthOrHeight,
                useWebWorker: true,
                initialQuality: 0.7,
                fileType: 'image/jpeg'
            });
            
            console.log('Файл сжат успешно:', {
                originalSize: file.size,
                compressedSize: compressedFile.size
            });

            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                
                reader.onload = async () => {
                    try {
                        const result = reader.result as string;
                        console.log('Файл прочитан успешно');
                        
                        if (result.startsWith('data:image')) {
                            const watermarkedImage = await addWatermark(result);
                            console.log('Водяной знак добавлен');
                            
                            const element = setImageStringTag 
                                ? `<div style='max-width: 80%; margin-left: auto; margin-right: auto; display: block; margin-bottom: 15px; page-break-inside: avoid;'>
                                     <img style='max-width: 100%; max-height: 450px; width: auto; height: auto; margin-left: auto; margin-right: auto; display: block; page-break-inside: avoid; border-radius: 10px' src='${watermarkedImage}' alt="Фото"/>
                                   </div>`
                                : watermarkedImage;
                                
                            setResult(element);
                            resolve(element);
                        } else {
                            throw new Error('Некорректный формат результата');
                        }
                    } catch (error) {
                        console.error('Ошибка при обработке результата:', error);
                        reject(error);
                    }
                };

                reader.onerror = (error) => {
                    console.error('Ошибка чтения файла:', error);
                    reject(error);
                };

                reader.readAsDataURL(compressedFile);
            });
        } catch (error) {
            console.error('Ошибка при сжатии или обработке:', error);
            throw error;
        }
    }

    function focusInput() {
        switch (entity.type) {
            case "Текст":
                textInput?.current?.focus()
                // @ts-ignore
                maskInput?.current?.element?.focus()
                break
            case "Фото":
                photoUploadRef.current?.click()
                break
            case "Дата":
                input.current?.focus()
                break
            case "Список":
                input.current?.focus()
                break
            default:
                input.current?.focus()
        }
    }

    function processSelectionOfEntity(entity: any) {
        let kw = `<<${entity.keyword}>>`;
        document.getElementsByName(kw).forEach((element: HTMLElement) => {
            element.style.setProperty("border", "1px solid #DE9B94");
            element.style.setProperty("transition-duration", "200ms");
            element.style.setProperty("transition-property", "background");
            element.style.setProperty(
                "background",
                isEditing ? "#DE9B94" : "None",
            )
        })
    }

    useEffect(
        () => {
            processSelectionOfEntity(entity)
        },
        []
    )

    useEffect(
        () => {
            setTimeout(() => {
                processSelectionOfEntity(entity)
            }, 50);
        },
        [isEditing]
    )

    function makeInput() {
        switch (entity.type) {
            case "Текст":
                return makeTextInput()
            case "Список":
                return makeSelectInput()
            case "Фото":
                if (entity.value) {
                    return makePhotoView()
                } else {
                    return makePhotoInput()
                }
            case "Дата":
                return makeDatePicker(input, isEditing, setIsEditing, entity, updateEntity)
            case "Формула":
                return makeFormulaView()
            case "Подпись":
                return makeSignatureInput()
            default:
                return null
        }
    }


    function makeTextInput() {
        if (entity.needNumberToWords) {
            return <IMaskInput
                ref={maskInput}
                placeholder={"Введите число..."}
                type="decimal"
                style={commonTextField()}
                value={entity.value?.toString()?.replaceAll('.', ',') ?? ''}
                onFocus={({target: {value}}: any) => {
                    setIsEditing(true)
                }}
                onBlur={({target: {value}}: any) => {
                    setIsEditing(false)
                }}
                mask={Number}
                disabled={false}
                onAccept={(value, mask) => {
                    updateEntity(value)
                }}
            />
        } else {
            return <TextareaStyled
                ref={textInput}
                value={entity.value}
                disabled={disabled}
                placeholder={"Введите значение..."}
                onFocus={({target: {value}}: any) => {
                    setIsEditing(true)
                }}
                onBlur={({target: {value}}: any) => {
                    setIsEditing(false)
                }}
                onChange={({target: {value}}: any) => {
                    updateEntity(value)
                }}
            />
        }
    }

    function makeFormulaView() {
        return <TextareaStyled
            ref={textInput}
            value={entity.value}
            disabled={true}
            placeholder={"Автоматически вычисляется"}
        />
    }

    function makeSelectInput() {
        return <FormControl sx={{width: "100%"}}>
            <Autocomplete
                freeSolo
                disablePortal
                handleHomeEndKeys={true}

                inputValue={entity.value}
                className="classicStyleMuiSelect"
                options={entity.list}
                sx={commonSelect()}
                onFocus={({target}) => {
                    setIsEditing(true)
                }}
                onBlur={({target}) => {
                    setIsEditing(false)
                }}
                onInputChange={(e, value, reason) => {
                    console.log("onInputChange", value, reason)
                    updateEntity(value)
                }}
                renderInput={(params) => (
                    <TextFieldStyled
                        inputRef={input}
                        {...params}
                        multiline
                        maxRows={1}
                        sx={{
                            border: isEditing ? "1px solid #75809E !important" : "none",
                            background: isEditing ? "white !important" : "#EAECF3",
                            padding: isEditing ? "1.5px !important" : "2.5px",
                        }}
                        variant="outlined"
                        placeholder={"Введите или выберите значение... "}
                    />
                )}
            />
        </FormControl>
    }

    function makePhotoInput() {
        const onlyFromCamera = entity.onlyFromCamera ?? false

        return <div>
            <button
                className="new withTitle secondary _wa"
                style={{height: "60px"}}
                onClick={(e) => {
                    photoUploadRef.current?.click()
                }}
            >
                <div
                    className="buttonsContainer"
                    style={{justifyContent: "center"}}
                >
                    {loading 
                        ? <CircularProgress size={"25px"} sx={{color: "#1FA3EE"}} />
                        : <Upload className="icon" style={{fill: "#0E1F4D"}}/>
                    }
                    <span>{onlyFromCamera ? "Сделать фото" : "Загрузить фото"}</span>
                </div>
            </button>
        </div>
    }

    // Выносим input за пределы условного рендеринга
    const photoInput = (
        <input
            type={"file"}
            accept="image/*"
            capture={entity.onlyFromCamera ? "environment" : undefined}
            ref={photoUploadRef}
            style={{display: "none"}}
            onChange={({target: {files}}) => {
                processPhotoInput(files)
            }}
        />
    );

    function makePhotoView() {
        return <div>
            {
                entity.value &&
                <div style={{
                    marginBottom: "10px",
                    borderRadius: "10px",
                    overflow: "hidden"
                }}
                    dangerouslySetInnerHTML={{ __html: entity.value }}
                    className="photo-container"
                />
            }

            <div className="titleContainer framed" style={{height: "60px", width: "100%"}}>
                <div className="buttonsContainer" style={{width: "100%", display: "flex", alignItems: "center"}}>
                    {loading && <CircularProgress sx={{color: "#1FA3EE"}} />}
                    {!loading && (
                        <>
                            <div style={{display: "flex", alignItems: "center", minWidth: 0, flex: 1, gap: "10px"}}>
                                <button
                                    className="new smallIconed"
                                    onClick={() => {
                                        setFileName("")
                                        updateEntity("")
                                    }}
                                >
                                    <Delete className="icon dark"/>
                                </button>
                                <button
                                    className="new smallIconed"
                                    onClick={() => photoUploadRef.current?.click()}
                                >
                                    <Refresh className="icon dark"/>
                                </button>
                                <div className="commonLabel hideOverflow" style={{
                                    minWidth: 0,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap"
                                }}>
                                    {fileName}
                                </div>
                            </div>
                        </>
                    )}
                    <div className="commonSubtitle" style={{marginLeft: "10px", whiteSpace: "nowrap"}}>
                        {formatBytes((entity.value?.toString() ?? "").length ?? 0, 0)}
                    </div>
                </div>
            </div>
        </div>
    }

    function makeSignatureInput() {
        const [showSignaturePopup, setShowSignaturePopup] = useState(false);

        return (
            <div>
                {entity.value ? (
                    <div style={{
                        maxWidth: "300px",
                        margin: "10px 0"
                    }}
                        dangerouslySetInnerHTML={{ __html: entity.value }}
                    />
                ) : null}
                
                <button
                    className="new withTitle _wa"
                    onClick={() => setShowSignaturePopup(true)}
                    disabled={disabled}
                >
                    {entity.value ? "Изменить подпись" : "Расписаться"}
                </button>

                {showSignaturePopup && (
                    <SignaturePopup
                        close={() => setShowSignaturePopup(false)}
                        onSave={(signature: string) => {
                            let signatureElement = `<img style='page-break-inside: avoid; border-radius: 10px' src='${signature}'  alt="Подпись"/>`
                            updateEntity(signatureElement);
                            setShowSignaturePopup(false);
                        }}
                    />
                )}
            </div>
        );
    }

    return (
        <>
            {photoInput}
            {makeInput()}
        </>
    )
}

EntityInput = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(EntityInput)

export {EntityInput, makeDatePicker}
