import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {useMediaQuery} from "@mui/material";
import QRCode from "react-qr-code";
import Popup from "../../components/Popup";
import notifyUser, {capitalize} from "../../methods/notifyUser";
import {connect} from "react-redux";
import {TextareaStyled} from "../Styles/CommonCSSProperties";
import {updateContractSettings} from "../../methods/updateContractSettings";
import {linkButtonsContainer, linkForClientElement} from "../../methods/linkForClientElement";
import {contractLink} from "../../utils/funcs/getContractLink";
import {firstSignerTitle, targetUserTitle} from "../../methods/workWithContract";
import {trackGoal} from "../../methods/trackGoal";
import {mobileMaxWidthMediaQuery} from "../../methods/utils";
import _ from "lodash";
import {DocumentType} from "../../methods/DocumentType";

type Props = {
    contractId: string,
    templateId: string | null,
    documentType: DocumentType,
    clientPhoneNumber: string | null,
    settings: any,
    shouldSign: boolean,
    close: Function,
}

function onCopy(props: any, message: string) {
    notifyUser(props, "Ссылка скопирована", message);
}

let NewContractPopup: any = function NewContractPopup(props: Props) {
    const [settings, setSettings] = useState(props.settings);
    const documentType = props.documentType ?? DocumentType.CONTRACT;

    const link = contractLink(props.contractId, false, documentType)

    useEffect(() => {
        notifyUser(props, `Скопируйте ссылку и отправьте вашему клиенту в любой мессенджер`);
        setTimeout(() => {
            try {
                navigator?.clipboard?.writeText(link)
                    .then(() => {
                        onCopy(props, "Теперь отправьте ссылку через любой мессенджер")
                    })
            } catch {
                trackGoal('copy_error', {link: link})
            }
        }, 100)
    }, []);

    const qrCode = <QRCode size={180} value={link} />

    const mobile = useMediaQuery(mobileMaxWidthMediaQuery)

    function linkTailForEntrepreneur(): string {
        return props.contractId + '?is_first_signer=true'
    }

    function linkTailForCustomer(): string {
        return props.contractId
    }

    return <Popup
        maxWidth="650px"
        onClose={() => {
            if (settings != props.settings) {
                updateContractSettings(props.templateId, settings)
            }
            props.close()
        }}
    >
        <div>
            <div className="commonTitle">{capitalize(documentType)} создан</div>
            <div className="titleContainer" style={{marginTop: "25px", gap: "20px"}}>
                {
                    linkForClientElement(
                        props,
                        props.shouldSign ? linkTailForCustomer() : linkTailForEntrepreneur(),
                        props.shouldSign ? '' : `Ссылка на ${documentType} для ${firstSignerTitle(settings)}`,
                        mobile,
                        targetUserTitle(settings)
                    )
                }
                {
                    !mobile &&
                    <div>
                        { qrCode }
                    </div>
                }

            </div>

            {
                !props.shouldSign &&
                <div>
                    <hr className="newHR" style={{margin: "30px auto"}}/>
                    {
                        linkForClientElement(
                            props,
                            linkTailForCustomer(),
                            '',
                            mobile,
                            targetUserTitle(props.settings),
                        )
                    }
                </div>
            }

            <hr className="newHR" style={{margin: "30px auto"}}/>

            <div>
                <div className="commonSubtitle">
                    Вы можете скопировать ссылку с подсказкой для клиента.
                    Подсказку можете редактировать, она сохранится для
                    следующего договора!
                </div>
                <TextareaStyled
                    value={settings.instructions?.pre_sign ?? ''}
                    style={{
                        marginTop: "10px",
                    }}
                    onChange={({target: {value}}: any) => {
                        let updatedSettings = {...settings}
                        _.set(updatedSettings, 'instructions.pre_sign', value)
                        setSettings(updatedSettings)
                    }}
                />

                {
                    linkButtonsContainer(
                        props,
                        link + "\n" + (settings.instructions?.pre_sign ?? '') ,
                        "Скопировать ссылку + инструкция",
                        mobile
                    )
                }
            </div>

            {
                mobile &&
                <div className="qr-code">
                    <QRCode value={link} />
                </div>
            }

            <hr className="newHR" style={{margin: "30px auto"}}/>

            <button
                className="new withTitle secondary"
                onClick={() => {

                    if (settings != props.settings) {
                        updateContractSettings(props.templateId, settings)
                    }
                    props.close()
                }}
            >
                Назад к списку договоров
            </button>
        </div>
    </Popup>
}

NewContractPopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(NewContractPopup)

export default NewContractPopup
