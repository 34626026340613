import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { FormControl, Select, MenuItem, ListItemText } from '@mui/material';
import { commonSelect, MenuProps } from '../pages/Styles/CommonCSSProperties';

type Props = {
    selectedRange: string;
    dateRange: {
        from: Date | null;
        to: Date | null;
    };
    onChange: (range: { from: Date | null; to: Date | null }, selectedRange: string) => void;
    layout?: 'inline' | 'stacked';
}

const dateRangeOptions = [
    { label: 'За всё время', value: 'all' },
    { label: 'За последнюю неделю', value: 'last_week' },
    { label: 'За последний месяц', value: 'last_month' },
    { label: 'За последние 3 месяца', value: 'last_3_months' },
    { label: 'За последние 6 месяцев', value: 'last_6_months' },
    { label: 'За последний год', value: 'last_year' },
    { label: 'Произвольный период', value: 'custom' }
];

export const DateRangeFilter: React.FC<Props> = ({ 
    dateRange, 
    onChange, 
    selectedRange,
    layout = 'stacked'
}) => {
    const [isEditingFromDate, setIsEditingFromDate] = useState(false);
    const [isEditingToDate, setIsEditingToDate] = useState(false);
    
    const handleRangeChange = (value: string) => {
        let newRange: { from: Date | null; to: Date | null } = { from: null, to: null };

        switch (value) {
            case 'last_week':
                newRange = {
                    from: dayjs().startOf('week').toDate(),
                    to: dayjs().endOf('week').toDate()
                };
                break;
            case 'last_month':
                newRange = {
                    from: dayjs().subtract(1, 'month').toDate(),
                    to: dayjs().toDate()
                };
                break;
            case 'last_3_months':
                newRange = {
                    from: dayjs().subtract(3, 'month').toDate(),
                    to: dayjs().toDate()
                };
                break;
            case 'last_6_months':
                newRange = {
                    from: dayjs().subtract(6, 'month').toDate(),
                    to: dayjs().toDate()
                };
                break;
            case 'last_year':
                newRange = {
                    from: dayjs().subtract(1, 'year').toDate(),
                    to: dayjs().toDate()
                };
                break;
            case 'all':
            default:
                newRange = { from: null, to: null };
        }

        onChange(newRange, value);
    };

    const handleCustomDateChange = (date: Date | null, type: 'from' | 'to') => {
        onChange({
            ...dateRange,
            [type]: date
        }, selectedRange);
    };

    function makeDatePicker(
        placeholder: string, 
        value: Date | null, 
        onChange: (date: Date | null, selectedRange: string) => void,
        isEditing: boolean,
        setIsEditing: (value: boolean) => void
    ) {
        const minDate = placeholder === "По дату" && dateRange.from ? dayjs(dateRange.from) : undefined;
        const maxDate = placeholder === "С даты" && dateRange.to ? dayjs(dateRange.to) : undefined;

        return (
            <DatePicker
                format="DD.MM.YYYY"
                value={value ? dayjs(value) : null}
                onChange={(newValue) => {
                    onChange(newValue ? newValue.toDate() : null, selectedRange);
                }}
                onOpen={() => setIsEditing(true)}
                onClose={() => setIsEditing(false)}
                minDate={minDate}
                maxDate={maxDate}
                slotProps={{
                    textField: {
                        size: "small",
                        placeholder: placeholder,
                        sx: { 
                            width: "100%",
                            border: isEditing ? "1px solid #75809E !important" : "none",
                            background: isEditing ? "white !important" : "#EAECF3",
                            padding: isEditing ? "1.5px !important" : "2.5px",
                        }
                    },
                    actionBar: {
                        actions: ['clear', 'cancel', 'accept']
                    }
                }}
            />
        );
    }

    function makeDateFilters() {
        return (
            <div style={{ 
                display: "flex", 
                gap: "10px",
                ...(layout === 'stacked' ? {
                    maxWidth: "440px",
                    width: "100%"
                } : {})
            }}>
                {makeDatePicker(
                    "С даты", 
                    dateRange.from,
                    (date) => handleCustomDateChange(date, 'from'),
                    isEditingFromDate,
                    setIsEditingFromDate
                )}
                {makeDatePicker(
                    "По дату", 
                    dateRange.to,
                    (date) => handleCustomDateChange(date, 'to'),
                    isEditingToDate,
                    setIsEditingToDate
                )}
            </div>
        );
    }
    
    return (
        <div style={{
            display: 'flex',
            flexDirection: layout === 'stacked' ? 'column' : 'row',
            gap: '16px',
            alignItems: layout === 'inline' ? 'center' : 'stretch',
            ...(layout === 'stacked' ? {
                width: '100%'
            } : {})
        }}>
            <FormControl sx={{ 
                minWidth: "200px", 
                ...(layout === 'stacked' ? {
                    maxWidth: "440px"
                } : {})
            }}>
                <Select
                    value={selectedRange}
                    onChange={(e) => handleRangeChange(e.target.value)}
                    labelId="date-range-label"
                    id="date-range"
                    className="classicStyleMuiSelect"
                    MenuProps={MenuProps}
                    sx={{...commonSelect("45px")}}
                >
                    {dateRangeOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            <ListItemText primary={option.label}/>  
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {selectedRange === 'custom' && makeDateFilters()}
        </div>
    );
}; 