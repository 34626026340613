import Popup from "components/Popup";
import notifyUser from "methods/notifyUser";
import { useRef } from "react";
import { connect } from "react-redux";
import SignaturePad from 'react-signature-canvas';

type Props = {
    close: () => void;
    onSave: (signature: string) => void;
}

let SignaturePopup: any = function SignaturePopup({ close, onSave }: Props) {
    const sigPad = useRef<any>();
    
    const clear = () => {
        sigPad.current.clear();
    }
    
    const save = () => {
        if (sigPad.current.isEmpty()) {
            notifyUser(null, "Пожалуйста, оставьте подпись");
            return;
        }
        const signature = sigPad.current.toDataURL();
        onSave(signature);
        close();
    }

    return (
        <Popup onClose={close}>
            <div className="signaturePopup">
                <div className="commonTitle" style={{marginBottom: "15px"}}>
                    Оставьте вашу подпись
                </div>
                <div style={{
                    border: "1px solid #E5E9EB",
                    borderRadius: "8px",
                    background: "white",
                    width: "100%",
                    height: "200px"
                }}>
                    <SignaturePad
                        ref={sigPad}
                        penColor='#000F55'
                        canvasProps={{
                            className: "signaturePad",
                            style: {
                                width: '100%',
                                height: '100%'
                            }
                        }}
                    />
                </div>
                <div className="buttonsContainer" style={{marginTop: "15px"}}>
                    <button 
                        className="new withTitle secondary _wa" 
                        onClick={clear}
                    >
                        Очистить
                    </button>
                    <button 
                        className="new withTitle _wa" 
                        onClick={save}
                    >
                        Сохранить
                    </button>
                </div>
            </div>
        </Popup>
    );
}

SignaturePopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(SignaturePopup)

export {SignaturePopup}