import React from 'react'
import "pages/Styles/EditContractCommon.sass"
import UserCardsView from "../CreateContract/UserCardsView";
import Stamp from "../CreateContract/Stamp";
import {getActualUser} from "../../methods/updateLocalEntities";

type Props = {
    template: any,
    showTip: boolean,
    $store: any,
    generateStamp?: boolean
}

function ContractFooter(props: Props) {
    const {template, showTip} = props

    return <>
        {
            showTip &&
            <div className="editContractFooterTitleContainer">
                Ниже приведенный абзац и штамп добавляются в конец каждого договора. Штамп заменяет раздел договора "Адреса и реквизиты", поэтому можете убрать его из тела договора'
            </div>
        }
        <div
            className="editContractFooterBodyContainer"
            style={{
                borderRadius: showTip ? "0px" : "20px"
            }}
        >
            {
                template.disclaimer &&
                <div>
                    <div dangerouslySetInnerHTML={{__html: template.disclaimer}}/>
                    <hr/>
                </div>
            }
            {
                props.generateStamp &&
                <Stamp
                    contract={props.template}
                    user={getActualUser(props.$store.userSession, template)}
                    allowEdit={false}
                />
            }
            {
                !props.generateStamp && template.stamp &&
                <div dangerouslySetInnerHTML={{__html: template.stamp}}/>
            }

        </div>
        {
            showTip &&
            <div className="editContractFooterTipContainer">
                Ваша подпись в штампе электронной подписи заполняется в виджете "Ваша подпись" в начале этой страницы
            </div>
        }
    </>

}

export default ContractFooter
