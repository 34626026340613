import getDomain from "methods/getDomain";
let domain: string | null = null
import {DocumentType} from "../../methods/DocumentType";

export function contractLink(contractId: string, isForFirstSigner: boolean = false, documentType: DocumentType = DocumentType.CONTRACT) {
  if (!domain) {
    domain = getDomain()
  }

  let link = ''
  if (domain.length == 0) {
    link = `localhost:3002/${documentType}/${contractId}`
  } else {
    link = `https://desktop.${domain}/${documentType}/${contractId}`;
  }

  if (isForFirstSigner) {
    link += '?is_first_signer=true'
  }

  return link
}

export function inviteLink(roleId: string) {
  if (!domain) {
    domain = getDomain()
  }

  if (domain.length == 0) {
    return `localhost:3002/invite/${roleId}`
  } else {
    return `https://desktop.${domain}/invite/${roleId}`;
  }
}

export function shareTemplateLink(shareLinkId: string) {
  if (!domain) {
    domain = getDomain()
  }

  if (domain.length == 0) {
    return `localhost:3002/shared-template/${shareLinkId}`
  } else {
    return `https://desktop.${domain}/shared-template/${shareLinkId}`;
  }
}
