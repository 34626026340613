import {connect} from "react-redux";
import React, {useState} from "react";
import {useRoute} from "react-router5";
import {State} from "router5";
import {Params} from "router5/dist/types/base";
import {Tooltip, useMediaQuery} from "@mui/material";

import {ReactComponent as Close} from "pages/Icons/size24/Close.svg"
import ErrorView from "../../Common/ErrorView";
import ContractStatusView from "../ContractStatusView";
import {ContractActionsButton, Flow} from "../ContractActionsButton";
import {Check, Edit} from "@mui/icons-material";
import FillEntitiesPopup from "./FillEntitiesPopup";
import {ContractHelpPopup} from "../../Templates/TemplatesHelpPopup";
import {contractStatus, ContractStatus} from "../../../methods/ContractStatus";
import {defaultUserFormForSigning} from "../../Auth/RegisterDefaultValues";
import {
    extraEntitiesToSend, validateCustomerRegisterFormWhileSigning, validateEntrepreneurRegisterFormWhileSigning,
} from "../../../methods/registerFormValidation";
import {mobileMaxWidthMediaQuery} from "../../../methods/utils";
import {EditSignedContractPopup} from "./EditSignedContractPopup";
import EnterTextPopup from "../../Common/EnterTextPopup";
import {DocumentType, getDocumentTypeText} from "../../../methods/DocumentType";
import { BackButton } from "components/BackButton";

type Props = {
    prevRoute: State | null,
    contract: any,
    isEditing: boolean,
    saveEditedVersion: Function,
    cancelEditing: Function,
    startEditing: Function,
    loadContract: Function,
    errorMessage: string | null,
    savingStatus: string,
    updateEntity: Function,
    updateName: Function,
    approveContract: Function,
    $commitToStore: Function,
    $store: any
}

interface prevParams {
    [name: string]: [string, Params];
}

const params: prevParams = {
    "contract": ["signed-contracts", {}],
};

let ContractViewHeader: any = function ContractViewHeader(props: Props) {
    const $router = useRoute();
    const documentType: DocumentType = props.contract.document_type
    const {
        prevRoute, contract, isEditing, saveEditedVersion, cancelEditing,
        startEditing, loadContract, savingStatus, updateEntity, approveContract,
        $commitToStore, $store
    } = props
    const userSession = $store.userSession
    const isCreatorMe = contract.created_by?._id === userSession?._id
    const isFirstSignerMe = contract.first_signer?._id === userSession?._id
    const isTargetUserMe = contract.target_user?._id === userSession?._id
    const [errorClosed, setErrorClosed] = useState(false);

    const mobile = useMediaQuery(mobileMaxWidthMediaQuery)

    const [showEditName, setShowEditName] = useState(false)
    const [showHelp, setShowHelp] = useState(false);
    const [fillEntitiesPopup, setFillEntitiesPopup] = useState(false)
    const [editButtonForEditSignedContractPopup, setEditButtonForEditSignedContractPopup] = useState(false)

    function back() {
        window.history.back()
    }

    function sign() {
        props.$commitToStore({
            loading: false
        })
        setFillEntitiesPopup(true)
    }

    function canSign() {
        if (contract.is_mass_template == true) {
            return true
        }

        if (contract.status?.internal_id != ContractStatus.Awaiting) {
            return false
        }

        let is_first_signer_link = $router.route.params.is_first_signer ?? false
        if (!userSession) {
            if (is_first_signer_link) {
                return !contract.first_signer
            } else {
                return !contract.target_user
            }
        }

        if (isCreatorMe || isFirstSignerMe || isTargetUserMe) {
            return false
        }

        if (is_first_signer_link) {
            return !contract.first_signer
        } else {
            return !contract.target_user
        }
    }

    function canEdit() {
        return !isEditing && contract.can_edit_contract
    }

    function showEditButtonForEditSignedContractPopup() {
        return contract.status?.internal_id == ContractStatus.Signed && documentType === DocumentType.CONTRACT && !contract.package_id
    }

    function onCancelContract() {
        if (userSession && (isCreatorMe || isFirstSignerMe || isTargetUserMe)) {
            loadContract()
        } else {
            $router.router.navigate('main')
        }
    }

    function signButtonTitle() {
        const is_first_signer = $router.route.params.is_first_signer ?? false
        let registerForm = {
            ...defaultUserFormForSigning(is_first_signer, contract, props.$store.userSession),
            entities: extraEntitiesToSend(contract, is_first_signer)
        }
        let validationErrors: string[] = []
        if (registerForm.client_role == 'customer') {
            validationErrors = validateCustomerRegisterFormWhileSigning(registerForm, contract, is_first_signer)
        } else if (registerForm.client_role == 'entrepreneur') {
            validationErrors = validateEntrepreneurRegisterFormWhileSigning(registerForm, contract, is_first_signer)
        }

        if (validationErrors.length) {
            return "Заполнить"
        } else {
            return "Подписать"
        }
    }

    function makeEditButtons() {
        if (contract.package_id) {
            return (
                <button
                    className="new withTitle"
                    onClick={() => {
                        $router.router.navigate('package', { packageId: contract.package_id })
                    }}
                >
                    Перейти к пакету документов
                </button>
            );
        }

        if (isEditing && !contract?.is_mass_template) {
            if (contract?.status.internal_id === 0) {
                return <button
                    className="new withTitle"
                    onClick={() => {
                        saveEditedVersion()
                    }}
                >
                    Подписать и отправить
                </button>
            }
            return <div className="buttonsContainer">
                <Tooltip arrow={true} title={"Отменить редактирование"}>
                    <button
                        className="new bigIconed"
                        style={{background: "#E22631"}}
                        onClick={() => {
                            cancelEditing()
                        }}
                    >
                        <Close className="icon" style={{stroke: "white"}}/>
                    </button>
                </Tooltip>
                <Tooltip arrow={true} title={"Сохранить отредактированную версию"}>
                    <button
                        className="new bigIconed"
                        style={{background: "#4EBA28"}}
                        onClick={() => {
                            saveEditedVersion()
                        }}
                    >
                        <Check className="icon" sx={{fill: "white"}}/>
                    </button>
                </Tooltip>
            </div>
        } else if (contractStatus(contract) == ContractStatus.AwaitingConfirmation && contract.created_by._id == props.$store.userSession?._id) {
            return <button
                className="new withTitle"
                onClick={() => {
                    approveContract()
                }}
            >
                Завершить проверку
            </button>
        }

        if (canEdit() || showEditButtonForEditSignedContractPopup()) {
            return <button
                className="new withTitle"
                onClick={() => {
                    if (showEditButtonForEditSignedContractPopup()) {
                        setEditButtonForEditSignedContractPopup(true)
                    } else {
                        startEditing()
                    }
                }}
            >
                Редактировать
            </button>
        }

        if (canSign()) {
            return <button
                className="new withTitle"
                onClick={() => {
                    sign()
                }}
            >
                {contract.sign_button_title ?? signButtonTitle()}
            </button>
        }

        return null
    }

    function mobileButtonsContainer() {
        return <div className="navbar">
            <div style={{padding: "20px 15px 15px 15px"}}>
                {
                    $store.userSession && <>
                        <div className="titleContainer">
                            <BackButton />

                            <div className="serviceMessage">
                                {savingStatus === "saving" ? "сохраняем..." : ""}
                                {savingStatus === "saved" ? "сохранено" : ""}
                            </div>
                        </div>
                        <hr className="newHR"/>
                    </>
                }
                <div
                    className="titleContainer"
                >
                    {
                        makeActionsButton()
                    }

                    {
                        canSign() && !contract.package_id &&
                        <button
                            className="new tip _wa"
                            onClick={() => {
                                setShowHelp(true)
                            }}
                        >
                            Как подписать?
                        </button>
                    }
                    {
                        makeEditButtons()
                    }
                </div>
            </div>
        </div>
    }

    function desktopButtonsContainer() {
        return <div className="titleContainer">
            <div className="buttonsContainer">
                <BackButton />

                <ContractActionsButton
                    flow={Flow.InDetailsSelect}
                    contract={contract}
                    reload={loadContract}
                    onCancelContract={onCancelContract}
                    $store={props.$store}
                    $commitToStore={props.$commitToStore}
                />
            </div>

            <div className="buttonsContainer">
                <div className="serviceMessage">
                    {savingStatus === "saving" ? "сохраняем..." : ""}
                    {savingStatus === "saved" ? "сохранено" : ""}
                </div>

                {
                    makeEditButtons()
                }
            </div>
        </div>
    }

    function makeActionsButton() {
        return <ContractActionsButton
            flow={Flow.InDetailsSelect}
            contract={contract}
            reload={loadContract}
            onCancelContract={onCancelContract}
            $store={props.$store}
            $commitToStore={props.$commitToStore}
        />
    }

    function desktopNameContainer() {
        return <div style={{marginTop: "25px"}}>
            {
                nameView()
            }

            <div style={{marginTop: "15px"}}>
                <ContractStatusView contract={contract} userSession={props.$store.userSession}/>
            </div>
        </div>
    }

    function mobileNameContainer() {
        return <div style={{marginTop: $store.userSession ? "148px" : "96px"}}>
            {
                nameView()
            }

            <div className="buttonsContainer" style={{marginTop: "15px"}}>
                <ContractStatusView contract={contract} userSession={props.$store.userSession}/>
            </div>
        </div>
    }

    function nameView() {
        return <div className="buttonsContainer">
            <div className="contractTitle">
                {contract.name}
            </div>

            {
                isEditing &&
                <button
                    className="new smallIconed"
                    onClick={() => {
                        setShowEditName(true)
                    }}
                >
                    <Edit className="icon dark"/>
                </button>
            }
        </div>
    }

    return <div>
        {
            mobile ? mobileButtonsContainer() : desktopButtonsContainer()
        }

        {
            mobile ? mobileNameContainer() : desktopNameContainer()
        }

        {
            contractStatus(contract) == ContractStatus.AwaitingConfirmation && contract.first_signer?._id == props.$store.userSession?._id &&
            <div className="commonSubtitle" style={{marginTop: "15px"}}>
                {getDocumentTypeText(documentType)} подписан вашим клиентом.
                <br/>
                Вам необходимо проверить введенные данные и завершить проверку если {getDocumentTypeText(documentType)} заполнен верно. Если в {documentType === DocumentType.PACKAGE ? "пакете" : "договоре"} присутствуют ошибки - вы можете отклонить {documentType === DocumentType.PACKAGE ? "его" : "договор"} по кнопке ✖️
            </div>
        }

        {
            contractStatus(contract) == ContractStatus.AwaitingConfirmation && contract.target_user?._id == props.$store.userSession?._id &&
            <div className="commonSubtitle" style={{marginTop: "15px"}}>
                Когда {getDocumentTypeText(documentType)} пройдет проверку, на вашу почту придет уведомление.
                <br/>
                Если {getDocumentTypeText(documentType)} долго висит в статусе проверки - обратитесь к тому, кто вам выслал ссылку на {getDocumentTypeText(documentType)}.
            </div>
        }

        {
            !errorClosed && props.errorMessage &&
            <ErrorView message={props.errorMessage} onClose={() => {setErrorClosed(true)}}/>
        }

        {
            showHelp &&
            <ContractHelpPopup onClose={() => {setShowHelp(false)}}/>
        }

        {
            showEditName &&
            <EnterTextPopup
                title={"Введите новое название для документа"}
                placeholder={"Название документ"}
                oldText={contract.name}
                onClose={() => {
                    setShowEditName(false)
                }}
                canSaveEmpty={false}
                saveNewName={(newName: string) => {
                    setShowEditName(false)
                    props.updateName(newName)
                }}
            />
        }

        {
            fillEntitiesPopup &&
            <FillEntitiesPopup
                contract={contract}
                close={() => {
                    setFillEntitiesPopup(false)
                }}
                updateEntity={updateEntity}
                finish={(contractId: string) => {
                    setFillEntitiesPopup(false)

                    if (contractId) {
                        // Навигация на страницу с договором, необходима для массовых шаблонов
                        let idKey = documentType === DocumentType.PACKAGE ? "packageId" : "contractId"
                        $router.router.navigate(
                            documentType,
                            {
                                [idKey]: contractId.length === 24 ? contractId : contract._id
                            }
                        )
                        location.reload()

                        if (contract.redirect_url) {
                            setTimeout(() => {
                                location.href = contract.redirect_url
                            }, 3000)
                        }
                    } else if (!props.$store.userSession) {
                        setTimeout(() => $router.router.navigate('main'), 0)
                    }
                }}
                $store={$store}
                $commitToStore={$commitToStore}
            />
        }

        {
            editButtonForEditSignedContractPopup &&
            <EditSignedContractPopup
                onClose={() => {
                    setEditButtonForEditSignedContractPopup(false)
                }}
                contract={contract}
                reload={loadContract}
                $store={props.$store}
                $commitToStore={props.$commitToStore}
            />
        }
    </div>
}

ContractViewHeader = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(ContractViewHeader)

export default ContractViewHeader
