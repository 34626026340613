import {TextareaStyled} from "../pages/Styles/CommonCSSProperties";
import cleanPhoneNumber from "./cleanPhoneNumber";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {clipboardCopyText} from "../utils/funcs/clipboardCopy";
import {openInNewTab} from "./openInNewTab";
import Icon from "../components/Icon";
import React from "react";
import notifyUser from "./notifyUser";
import {ReactComponent as Copy} from "../pages/Icons/size24/Copy.svg";
import {trackGoal} from "./trackGoal";
import {contractLink} from "../utils/funcs/getContractLink";
import {DocumentType} from "./DocumentType";

export function linkForClientElement(props: any, contractId: string, title: string = '', isMobile: boolean, targetUserTitle: string = "") {
    const documentType = props.documentType ?? DocumentType.CONTRACT;
    const link = contractLink(contractId, false, documentType);
    return <div>
        <div className="commonLabel" style={{width: "100%"}}>
            {title ? title : `Ссылка на ${documentType} для ${targetUserTitle.length > 0 ? targetUserTitle : "клиента"}`}
        </div>
        <div className="commonSubtitle">
            Если вы хотите увидеть, как {documentType} увидит ваш клиент - откройте эту ссылку в другом браузере или на другом устройстве
        </div>
        <TextareaStyled
            style={{
                marginTop: "10px",
            }}
            readOnly
            value={link}
        />
        {
            linkButtonsContainer(props, link, `Скопировать ссылку`, isMobile)
        }
    </div>
}

export function linkButtonsContainer(props: any, copyText: string, buttonTitle: string, isMobile: boolean) {
    let waPhone = cleanPhoneNumber(props.clientPhoneNumber ?? "")
    let tgPhone = waPhone.length > 0 ? (waPhone[0] == "+" ? waPhone : "+" + waPhone) : ""

    return <div style={{marginTop: "15px"}}>
        <CopyToClipboard
            onCopy={() => {onCopy(props, "Теперь отправьте ссылку через любой мессенджер")}}
            text={copyText}
        >
            <button className="new withTitle _wa">
                <div className="buttonsContainer">
                    <Copy className="icon" style={{fill: "white"}}/>
                    <span>{buttonTitle}</span>
                </div>
            </button>
        </CopyToClipboard>
        {
            isMobile &&
            <div className="buttonsContainer" style={{marginTop: "15px"}}>
                <div className="commonLabel">
                    Отправить ссылку через:
                </div>
                <button className="new" onClick={async () => {
                    await clipboardCopyText(copyText)
                    trackGoal('open_tg')
                    openInNewTab(`https://t.me/${tgPhone}`)
                }}>
                    <div style={{display: "flex", height: "30px", width: "30px"}}>
                        <Icon icon="telegram-4" className="__telegram"/>
                    </div>
                </button>
                <button className="new" onClick={async () => {
                    await clipboardCopyText(copyText)
                    trackGoal('open_wa')
                    openInNewTab(waPhone ? `https://wa.me/${waPhone}` : 'https://api.whatsapp.com')
                }}>
                    <div style={{display: "flex", height: "30px", width: "30px"}}>
                        <Icon icon="whatsapp-1" className="__whatsapp"/>
                    </div>
                </button>
            </div>
        }
    </div>
}

function onCopy(props: any, message: string) {
    notifyUser(props, "Ссылка скопирована", message);
}