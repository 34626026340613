import React, { useState } from "react"

import Popup from "components/Popup"
import {TextareaStyled} from "../Styles/CommonCSSProperties";
import {httpClient, nError} from "../../funcs";
import notifyUser, {notifyAboutError} from "../../methods/notifyUser";
import {CircularProgress} from "@mui/material";
import _ from "lodash";
import {ContractStatus} from "../../methods/ContractStatus";
import {DocumentType, getDocumentTypeText} from "../../methods/DocumentType";
let DeclineContractPopup: any = function (props: any) {

    const [cancellationText, setCancellationText] = useState("")
    const [loading, setLoading] = useState(false)

    function title() {
        return props.contractStatus == ContractStatus.Signed
            ? `Форма расторжения ${getDocumentTypeText(props.documentType)}`
            : `Форма отклонения ${getDocumentTypeText(props.documentType)}`
    }

    function placeholder() {
        return props.contractStatus == ContractStatus.Signed
            ? "Причина расторжения..."
            : "Причина отклонения..."
    }

    function notificationMessage() {
        return props.contractStatus == ContractStatus.Signed
            ? `${getDocumentTypeText(props.documentType)} расторгнут. Второй стороне договора отправлено соглашение о расторжении`
            : `${getDocumentTypeText(props.documentType)} отклонён`
    }

    function buttonTitle() {
        return props.contractStatus == ContractStatus.Signed
            ? "Расторгнуть"
            : "Отклонить"
    }


    async function cancelContract(text: string) {
        setLoading(true)
        try {
            switch (props.documentType) {
                case DocumentType.CONTRACT:
                    await httpClient.put(`/contract/${props.contractId}/cancel`, {
                        changeStatusText: text,
                    });
                    break;
                case DocumentType.PACKAGE:
                    await httpClient.put(`/contract-packages/${props.contractId}/cancel`, {
                        changeStatusText: text,
                    });
                    break;
            }

            notifyUser(props, notificationMessage());
            props.reload()
        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            setLoading(false)
            props.close()
        }
    }

    return (
        <Popup onClose={props.close}>
            <>
                <div>
                    <div className="commonLabel" style={{marginTop: "25px", textAlign: "center"}}>
                        {title()}
                    </div>

                    {
                        props.contractStatus == ContractStatus.Signed &&
                        <div className="commonSubtitle">
                            Сервис автоматически сгенерирует соглашение о расторжении и отправит ссылку на подписание этого соглашения второй стороне.
                        </div>
                    }
                </div>

                <TextareaStyled
                    placeholder={placeholder()}
                    value={cancellationText}
                    style={{
                        marginTop: "15px",
                    }}
                    onChange={({target: {value}}: any) => {
                        setCancellationText(value)
                    }}
                />

                <hr className="newHR"/>

                <button
                    className="new withTitle _wa"
                    disabled={loading}
                    style={{marginTop: "15px",}}
                    onClick={async () => {
                        await cancelContract(cancellationText)
                    }}
                >
                    <div className={"buttonsContainer"}>
                        { loading && <CircularProgress size={"25px"} sx={{color: "white"}}/>}
                        {buttonTitle()}
                    </div>
                </button>
            </>
        </Popup>)
}


export default DeclineContractPopup
