import axios from 'axios'
import moment from 'moment'
import 'moment/locale/ru'
import {getToken} from "./methods/saveToken";

export function nError(error: any) {

  // HTTP error (axios)
  if (error.response) {
    console.warn(error.request, error.response)
    return {
      error,
      notification: {
        title: `Ошибка №${error.response.status}`,
        content: `${error.response.data}`
      }
    }
  }

  // Another error
  else if (error.code == "ERR_NETWORK") {
    return {
      error,
      notification: {
        title: 'Возникла ошибка',
        content: 'Проверьте наличие соединения с интернетом или попробуйте попытку позже'
      }
    }
  } else {
    console.error(error)
  }

  // Return error content
  return {
    error,
    notification: {
      title: 'Возникла ошибка',
      content: error.message
    }
  }
}

export function preparePhoneNumber(str: string): string {
  str = str.replace(/[^\d]/g, '').replace(/^8/, '7')

  if (str.length <= 10)
    return str

  return '+' + str
}

export function isSMSAllowedForPhoneNumber(phone_number: string, disabled_code_channels: string[] = []) {
  if (!phone_number) {
    return false
  }

  try {
    if (rfOrRBPhoneNumber(phone_number)) {
      return !disabled_code_channels.includes('sms')
    } else {
      return false
    }
  } catch {
    return false
  }
}

export function isCallAllowedForPhoneNumber(phone_number: string, disabled_code_channels: string[] = []) {
  return !disabled_code_channels.includes('call')
}
export function isTGAllowedForPhoneNumber(phone_number: string, disabled_code_channels: string[] = []) {
  return !disabled_code_channels.includes('tg')
}

export function prepareSeveralPhoneNumbers(str: string): string {

  str = str.replace(/[^\d]/g, '').replace(/^8/, '7')

  if (str.length <= 10)
    throw new Error("Неправильный номер телефона")

  return '+' + str
}

export const httpClient = makeClient()

function makeClient() {
  let client = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })
  let token = getToken()
  if (token) {
    client.defaults.headers['Authorization'] = `Bearer ${getToken()}`
  }
  return client
}

export function toHumanTime(seconds: any) {
  return new Date(seconds * 1000).toISOString().substr(11, 8)
}

export function rand(min: number, max: number): number {
  return Math.round(Math.random() * (max - min) + min)
}

// Initiate moment
moment.locale('ru')
export const $moment = moment

export function rfOrRBPhoneNumber(phone_number: string) {
  if (!phone_number) {
    return false
  }

  try {
    let prepared_phone_number = preparePhoneNumber(phone_number)
    switch (prepared_phone_number.length) {
      case 12:
        let rfPrefix = prepared_phone_number.slice(0, 3)
        return rfPrefix == '+79'
      case 13:
        let rbPrefix = prepared_phone_number.slice(0, 4)
        return rbPrefix == '+375'
      default:
        return false
    }
  } catch {
    return false
  }
}
