import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {connect} from "react-redux";
import _ from "lodash";
import {canEditSystemEntityById, contractSystemEntity} from "../../../methods/systemEntityValueByKeyword";
import {ChoosePassportTypeProps} from "./ChoosePassportTypeForm";
import {PassportForm, PhotoInputProps, SignatureInputProps} from "./PassportForm";
import {FormInputMask, FormInputPlaceholder, FormInputProps} from "./FormInput";
import {updateEntityInEditor} from "../../../methods/updateEntityInEditor";
import {makePassportSettingsAdapter} from "../../../methods/registerFormValidation";
import {
    clientPassportIssuedAt, clientPassportIssuedBy, clientPassportIssuedByCode,
    clientPassportNumber, clientPassportPhoto,
    clientPassportRegistration, clientPassportRegistrationPhoto, clientPassportSelfiePhoto,
    clientPassportType,
    clientSignature,
    PassportType
} from "../../../methods/SystemEntity";
import {passportTypeFromSelectedValues} from "../RegisterDefaultValues";

type Props = {
    contract: any,
    registerForm: any,
    updateRegisterForm: Function,
    fillByAdmin: boolean,
    user: any
}

export let PassportFormContainer: any = function PassportFormContainer(props: Props) {
    const {contract, registerForm, updateRegisterForm, fillByAdmin, user} = props

    let passport_settings = makePassportSettingsAdapter(contract)

    function passportType(): string {
        if (registerForm.client_passport_type) {
            return registerForm.client_passport_type
        } else {
            return passportTypeFromSelectedValues(contract)
        }
    }

    const passportIsRequired = (): boolean => {
        if (user && user.role == 'entrepreneur') {
            return false
        }

        if (!contract) {
            return false
        }

        return passport_settings.enabled
    }

    const choosePassportTypeForm = (): (ChoosePassportTypeProps | undefined) => {
        if (!contract) {
            return
        }

        if (!fillByAdmin && !canEditSystemEntityById(contract, clientPassportType.id)) {
            return
        }

        let entity = contractSystemEntity(contract, clientPassportType.id) ?? clientPassportType
        if (entity.selectedValues && entity.selectedValues.length <= 1) {
            return
        }

        return {
            client_passport_type: passportType(),
            allowNotSelectedOptions: fillByAdmin,
            allowedPassportTypes: entity.selectedValues ?? [PassportType.russianPassport],
            onChange: (value: string) => {
                updateRegisterForm(clientPassportType.id, value)
            }
        }
    }
    const numberInput = (): (FormInputProps | undefined) => {
        if (passportType()) {
            return {
                placeholder: passportType() === PassportType.russianPassport
                    ? FormInputPlaceholder.passportNumberRF
                    : FormInputPlaceholder.passportNumberForeignCountry,
                value: registerForm.client_passport_number,
                disabled: !fillByAdmin && !canEditSystemEntityById(contract, clientPassportNumber.id),
                onChange: (value: string) => {
                    updateRegisterForm(clientPassportNumber.id, value)
                    updateEntityInEditor({ keyword: clientPassportNumber.keyword, value: value, type: "Текст"})
                },
                mask: passportType() === PassportType.russianPassport
                    ? FormInputMask.passportNumber
                    : undefined
            }
        }
    }


    const issuedAtInput = (): (FormInputProps | undefined) => {
        if (passport_settings.issued_at && passportType()) {
            return {
                placeholder: FormInputPlaceholder.passportIssuedAt,
                value: registerForm.client_passport_issued_at,
                disabled: !fillByAdmin && !canEditSystemEntityById(contract,clientPassportIssuedAt.id),
                onChange: (value: string) => {
                    updateRegisterForm(clientPassportIssuedAt.id, value)
                    updateEntityInEditor({ keyword: clientPassportIssuedAt.keyword, value: value, type: "Текст"})
                },
                mask: FormInputMask.passportIssuedAt
            }
        }
    }

    const issuedByCodeInput = (): (FormInputProps | undefined) => {
        if (passport_settings.issued_by_code && passportType() == PassportType.russianPassport) {
            return {
                placeholder: FormInputPlaceholder.passportIssuedByCode,
                value: registerForm.client_passport_issued_by_code,
                disabled: !fillByAdmin && !canEditSystemEntityById(contract, clientPassportIssuedByCode.id),
                onChange: (value: string) => {
                    updateRegisterForm(clientPassportIssuedByCode.id, value)
                    updateEntityInEditor({ keyword: clientPassportIssuedByCode.keyword, value: value, type: "Текст"})
                },
                mask: FormInputMask.passportIssuedByCode
            }
        }
    }

    const issuedByInput = (): (FormInputProps | undefined) => {
        if (passport_settings.issued_by && passportType() == PassportType.russianPassport) {
            return {
                placeholder: FormInputPlaceholder.passportIssuedBy,
                value: registerForm.client_passport_issued_by,
                disabled: !fillByAdmin && !canEditSystemEntityById(contract, clientPassportIssuedBy.id, clientPassportIssuedBy.keyword),
                onChange: (value: string) => {
                    updateRegisterForm(clientPassportIssuedBy.id, value)
                    updateEntityInEditor({keyword: clientPassportIssuedBy.keyword, value: value, type: "Текст"})
                }
            }
        }
    }

    const registrationInput = (): (FormInputProps | undefined) => {
        if (passport_settings.registration && passportType() == PassportType.russianPassport) {
            return {
                placeholder: FormInputPlaceholder.passportRegistration,
                value: registerForm.client_passport_registration,
                disabled: !fillByAdmin && !canEditSystemEntityById(contract, clientPassportRegistration.id, clientPassportRegistration.keyword),
                onChange: (value: string) => {
                    updateRegisterForm(clientPassportRegistration.id, value)
                    updateEntityInEditor({keyword: clientPassportRegistration.keyword, value: value, type: "Текст"})
                }
            }
        }
    }

    const signatureInput = (): (SignatureInputProps | undefined) => {
        if (passport_settings.signature && !fillByAdmin) {
            return {
                path: "path",
                value: registerForm.client_signature,
                keyword: clientSignature.keyword,
                onChange: (value: string) => {
                    updateRegisterForm(clientSignature.id, value)
                }
            }
        }
    }

    const photoInput = (path: string, keyword: string): (PhotoInputProps | undefined) => {
        if (_.get(passport_settings, path) == true && passportType()) {
            let actualPath = 'client_passport_' + path
            return {
                path: "path",
                value: _.get(registerForm, actualPath) ?? "",
                keyword: keyword,
                onlyFromCamera: contractSystemEntity(contract, keyword)?.onlyFromCamera && fillByAdmin !== true,
                onChange: (value: string) => {
                    updateRegisterForm(actualPath, value)
                }
            }
        }
    }


    return <div>
        {
            passportIsRequired() &&
            <PassportForm
                form={{
                    choosePassportType: choosePassportTypeForm(),

                    number: numberInput(),
                    issuedAt: issuedAtInput(),
                    issuedByCode: issuedByCodeInput(),
                    issuedBy: issuedByInput(),
                    registration: registrationInput(),

                    signature: signatureInput(),

                    showPhotoDisclaimer: !props.fillByAdmin,
                    photo: photoInput("photo", clientPassportPhoto.keyword),
                    selfie: photoInput("selfie_photo", clientPassportSelfiePhoto.keyword),
                    registrationPhoto: photoInput("registration_photo", clientPassportRegistrationPhoto.keyword)
                }}
            />
        }
    </div>
}

PassportFormContainer = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(PassportFormContainer)
