import { connect } from "react-redux"
import { $moment } from 'funcs'

let NavBalance: any = function (props: any) {
    const userSession = props.$store.userSession;
    const balance = props.$store.balance;
    const tariff = userSession?.tariff;
    const tariffType = tariff?.type ?? 'package'

    const getBalanceText = () => {
        // Если есть активная подписка
        if (tariffType == 'subscription' && userSession?.next_subscription_charge_date) {
            const endDate = $moment(userSession?.next_subscription_charge_date).format('D.M.YYYY');
            return `Подписка до ${endDate}`;
        }
        
        // Если есть баланс договоров
        if (balance >= 0) {
            return `Баланс: ${balance}`;
        }
        
        return null;
    };

    const balanceText = getBalanceText();
    if (!balanceText) return null;

    return (
        <span style={{
            color: '#75809E',
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '16px'
        }}>
            {balanceText}
        </span>
    );
}

NavBalance = connect(
    (store) => ({ $store: store })
)(NavBalance);

export default NavBalance; 